<div #pageTop >
  <div class="failure-reasons" #scroll>

    <h2>{{ authorityShortName }}, usecase: "{{ useCase }}" {{ inputFormat ? 'format: ' + inputFormat : ''}}{{ dataTypeTitle }}</h2>
    <textarea #taInputData (input)="updateInputData(taInputData.value)"
              placeholder="Insert here the message ( {{ inputFormat }}{{ dataTypeTitle }} ) to be tested...."></textarea>

    <div style="margin-top: 10pt">
      <button mat-button (click)="parseData()" [disabled]="isBtnParseDataDisabled" [class.spinner]="isBtnLoadingSpinnerVisible"
              class="parseDataBtn">{{ btnParseMessage }}</button>
      <h4 *ngIf="totResponses" class="numOfResponses">Number of responses: <a (click)="scrollToMessages()" style="text-decoration: underline">{{ totResponses }}</a></h4>
    </div>

    <div class="alert alert-warning horizontallyCenterContainer" role="alert" [style.display]="showWarningMsg ? 'block': 'none'">
      Data can not be empty.
    </div>

    <div class="horizontallyCenterContainerAndContent">

      <button [disabled]="type.disabled" [disableRipple]="true"
              [class]=" 'polylineTypeBtn' + type.styleName"
              matTooltip="Filter not available"
              mat-button *ngFor="let type of polylineTypeLegends; let i = index" >
        {{ type.name }}
      </button>

    </div>

    <app-event-map
      [mapTarget]="useCase + authorityShortName"
      [useCase]="useCase"
      class="preventMapDoubleClickSelection"
      [hidden]="!isMapVisible">
    </app-event-map>

    <div *ngFor="let element of parsedElements" class="parsedElement" #messages [class]="element.success ? 'success' : 'error'">

      <div class="toggle-children">
        <h4 class="children-title">{{ element.title }}</h4>
        <div class="children-centerMap">
          <!-- NOT WORKING -->
<!--          <h4 (click)="centerMapOnEvent(element.location)" *ngIf="element.location !== null">-->
<!--            View on Map <img src="../../assets/img/pin-map-fill.svg"  alt=""/>-->
<!--          </h4>-->
        </div>
        <mat-icon class="children-toggle" (click)="toggleElement(element)">
          {{element.visible ? 'arrow_drop_down' : 'arrow_drop_up' }}
        </mat-icon>
      </div>

      <pre *ngIf="element.visible">{{ element.description }}</pre>
    </div>

    <button mat-button (click)="scrollToTop()" class="back-to-top" matTooltip="Back to Top" *ngIf="showScrollToTopButton">
      <mat-icon>arrow_upward</mat-icon>
    </button>
  </div>
</div>
