<div class="city-stats">
  <ngx-charts-advanced-pie-chart
    [gradient]="true" [scheme]="colorScheme"
    [animations]="false"
    [results]="getResults()"
    [view]="view"
    [percentageFormatting]="percentageFormatting"
    label="Total number of messages" class="testStyleChart" >

  </ngx-charts-advanced-pie-chart>

</div>
