export class FailureMessageModel {

  readonly situationRecordID: string;
  readonly reasonOfFailure: string;
  readonly date: string;

  constructor(situationRecordID: string, reasonOfFailure: string, date: string) {
    this.situationRecordID = situationRecordID;
    this.reasonOfFailure = reasonOfFailure;
    this.date = date;
  }

}

