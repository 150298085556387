import {Component, Input, OnInit } from '@angular/core';
import {MenuItem} from '../model/menu.item.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @Input() items: MenuItem[];

  constructor(private router: Router) { }

  ngOnInit(): void { }

  openHomePage(): void {

      this.router.navigate([ 'dashboard' ]);

  }
}
