import {Component, Injectable, Input, OnInit} from '@angular/core';
import {MenuItem} from '../model/menu.item.model';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {animate, style, transition, trigger} from '@angular/animations';
import {AppComponent} from '../app.component';


@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.css'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({opacity: 0}),
        animate(500, style({opacity: 1}))
      ]),
      transition('* => void', [
        animate(200, style({opacity: 0}))
      ])
    ])
  ]
})
@Injectable({
  providedIn: 'any'
})
export class SidebarItemComponent implements OnInit {

  @Input() itemInfo: MenuItem;
  @Input() compact: boolean;
  @Input() outlet: RouterOutlet;

  hover = false;
  showChildren = false;

  constructor(private router: Router, private activeRoute: ActivatedRoute,
              private appComponent: AppComponent)
  {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.showChildren = this.isSelected();
  }

  onClick(): void{
    // if ( !this.isSelected()) { // when the item is selected, clicking it again has no effects
    this.router.navigate(this.itemInfo.route, {
      queryParamsHandling: 'preserve',
    });
    // }
  }

  isSelected(): boolean{

    /* Note: url base structure: "path/sub/sub/page"
    * - if queryParams are specified: "path/sub/sub/page?par1=1&par2=2" */
    let url = this.router.url;
    const routerUrl = url.split('?');
    const itemUrl = this.itemInfo.route.join('/');

    if (routerUrl.length > 1) {
      url = routerUrl[0];
    }

    if ( this.compact || !this.showChildren ){
      return url.indexOf(itemUrl) !== -1;
    } else {
      return url.endsWith(itemUrl);
    }
  }

  onEnter(): void{
    if ( !this.isSelected() ) {
      this.hover = true;
    }
  }

  onOut(): void{

    this.hover = false;

  }

  getIcon(val: string): string{

    return ( this.hover ? val + '_hover.svg' : val + '.svg');

  }

  toggleChildren($event): void{

    $event.stopPropagation();
    $event.preventDefault();
    this.showChildren = !this.showChildren;

  }

  updateQueryParams(val: number): void {
    // Save the value inside a shared variable
    this.appComponent.setDateFilterParam(val);
  }
}
