import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs'; // !!! NOTE: usare Observable "RXJS"
import {PermissionResponseModel} from '../model/permission.response.model';
import {environment} from '../../environments/environment';
import {AuthenticationService} from './authentication.service';
import {UtilityFunctions} from './utility-functions';
import {map} from 'rxjs/operators';

// File structure Auto-generate with command: "ng cgenerate service authorities"

@Injectable({
  providedIn: 'root'
})
export class AuthoritiesService extends UtilityFunctions {


  // keyword "private" allows to automatically declare a variable httpClient and to do this.httpClient = httpClient
  constructor(private httpClient: HttpClient,
              private authenticationService: AuthenticationService) {
    super();
  }

  public getAuthorities(): Observable<PermissionResponseModel> {
    const url = environment.baseUrl + 'users/me/authorities';

    const options = this.HttpRequestHeaders(this.authenticationService.idToken);
    return this.httpClient.get<PermissionResponseModel>(url, { headers: options }).pipe(
      map(res => {
        res.permissions.forEach(permission => {
          permission.useCases.sort( (a, b) => {
            return a.name.localeCompare(b.name);
          });
        });

        return res;
      })
    );
  }

  public getSingleAuthorityPermissions(compactName: string): Observable<PermissionResponseModel> {
    const url = environment.baseUrl + 'users/me/authorities/' + compactName;

    return this.httpClient.get<PermissionResponseModel>(url, { headers: this.HttpRequestHeaders(this.authenticationService.idToken) });
  }
}
