import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TimeFilter} from '../model/time.filter.model';
import {DashboardCityData} from '../model/dashboard.city.data.model';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {StatisticsService} from '../service/statistics.service';
import {Title} from '@angular/platform-browser';
import {interval, Subscription} from 'rxjs';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {SidebarItemComponent} from '../sidebar-item/sidebar-item.component';
import {AuthenticationService} from '../service/authentication.service';
import {AppComponent} from '../app.component';
import {TimeFilterComponent} from '../time-filter/time-filter.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {getFormattedDate} from '../util';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  selectedAuthority: string;

  // Date used to extract data from the DB
  lastDate: string;

  // Used during page initialization and to propagate timeFilterIndex to other pages
  timeFilterSelected: TimeFilter;
  timeFilterIndex: number;
  timeFilterIsLoading: boolean; // Enable/Disable timeFilter buttons when the page is loading

  // Overall number of Successful and Failed messages
  totalStats: DashboardCityData;

  title = 'Loading Statistics...';
  pageTitle = 'Authorities Messages';
  statisticsData: DashboardCityData[]; // Failed and Successful messages grouped by authority

  refreshPageTimer: Subscription;

  hiddenAlertDanger = true;
  hiddenAlertTableContentIsLoading = false;

  constructor(private router: Router, private activeRoute: ActivatedRoute,
              private titleService: Title,
              private statisticsService: StatisticsService,
              private authenticationService: AuthenticationService,
              private appComponent: AppComponent,
              private sidebarItemComponent: SidebarItemComponent,
              private snackBar: MatSnackBar)
  {
    this.titleService.setTitle(this.pageTitle);
  }

  ngOnInit(): void {

    // While the page is loading the data from the server, disable the timeFilter buttons
    this.timeFilterIsLoading = true;

    this.timeFilterSelected = TimeFilter.LAST_WEEK;
    this.getAppComponentDateFilterParam();

    this.lastDate = this.computeUTCDate(this.timeFilterSelected);
    this.timeFilterIndex = this.getTimeFilterIndex();

    this.totalStats = new DashboardCityData('ALL', 0, 0, 'ALL');
    this.statisticsData = [];

    this.getStatisticsData();

    // Enable all buttons when the page has successfully loaded the data from the server
    this.timeFilterIsLoading = false;

    // Refresh data every 1 minute
    this.refreshPageTimer = interval(60000).subscribe(val => {
      this.updateStatisticsData();
    });

  }

  ngOnDestroy(): void {
    this.refreshPageTimer.unsubscribe(); // stop the timer
  }

  computeUTCDate(date): string {
    // Note: today's date is computed as "yyyy-mm-YESTERDAY T 23:00:00 Z"
    // Note: it also generates milliseconds (e.g., 2015-12-02T21:45:22.279Z)
    const dateUTC = new Date(date).toISOString();
    const tmpDate = dateUTC.split('.');

    return tmpDate[0] + 'Z';
  }

  filterChanged($event: TimeFilter): void {
    this.timeFilterSelected = $event;
    this.lastDate = this.computeUTCDate(this.timeFilterSelected);
    this.getStatisticsData();
    this.timeFilterIndex = this.getTimeFilterIndex();

    // used to propagate information related to selected timeFilter
    this.sidebarItemComponent.updateQueryParams(this.timeFilterIndex);
  }

  private getAppComponentDateFilterParam(): void {
    // Check if the AppComponent contains a valid 'dateFilter'
    const dateFilter = this.appComponent.getDateFilterParam();

    if (dateFilter != null) {
      if (!isNaN(dateFilter)) {
        const dateIndex = dateFilter;
        if (dateIndex >= 0 && dateIndex < this.getTimeFilterValues().length) {
          this.timeFilterSelected = this.getTimeFilterValues()[dateIndex];
          this.timeFilterIndex = dateFilter;
        }
      }
    }
  }

  // getFormattedDate(date: string = this.lastDate): string {
  //   return new Date(date)
  //     .toLocaleDateString('it-IT'); // format: dd/MM/yyyy, HH:mm:ss
  // }

  getStatisticsData(date: string = this.lastDate): void {

    // When the table content reloads, remove the displayed error or warning and show the loading message
    this.hiddenAlertTableContentIsLoading = false;
    this.hiddenAlertDanger = true;

    this.statisticsService.getStatisticsMessages(date).subscribe(statsResponse => {

        this.statisticsData = [];
        let totSuccess = 0;
        let totFailure = 0;
        statsResponse.statistics.forEach(s => {
          this.statisticsData.push(new DashboardCityData(s.authorityName, s.numberOfSuccessful, s.numberOfFailed, s.compactName));
          totSuccess += s.numberOfSuccessful;
          totFailure += s.numberOfFailed;
        });
        this.totalStats.setNumberOfSuccessful(totSuccess);
        this.totalStats.setNumberOfFailed(totFailure);

        this.title = 'Statistics from: "' + getFormattedDate(date) + '"';

        // Hide alert messages
        this.hiddenAlertTableContentIsLoading = true;
        this.hiddenAlertDanger = true;
      },
      error => {
        this.hiddenAlertTableContentIsLoading = true;
        this.hiddenAlertDanger = false;
      });
  }

  getTimeFilterValues(): TimeFilter[] {
    return [TimeFilter.TODAY, TimeFilter.LAST_WEEK, TimeFilter.LAST_MONTH, TimeFilter.LAST_YEAR, TimeFilter.ALL];
  }

  getTimeFilterIndex(): number {
    const filters = this.getTimeFilterValues();

    for (let i = 0; i < filters.length; i++) {
      if (filters[i] === this.timeFilterSelected) {
        return i;
      }
    }

    return 1; // return default value
  }

  refreshPageContent(): void {
    this.updateStatisticsData();
  }

  selectionChanged($event: MatTabChangeEvent): void {
    this.selectedAuthority = this.statisticsData[$event.index].compactName;
  }

  updateStatisticsData(date: string = this.lastDate): void {
    console.log('Updating statistic data: ' + new Date().toLocaleString());

    // When the table content reloads, remove the displayed error or warning and show the loading message
    this.hiddenAlertTableContentIsLoading = false;
    this.hiddenAlertDanger = true;

    this.statisticsService.getStatisticsMessages(date).subscribe(statsResponse => {
        let totSuccess = 0;
        let totFailure = 0;
        statsResponse.statistics.forEach(s => {

          this.statisticsData.forEach(sd => {
            if (sd.authorityName === s.authorityName) {
              sd.numberOfFailed = s.numberOfFailed;
              sd.numberOfSuccessful = s.numberOfSuccessful;
            }
          });
          totSuccess += s.numberOfSuccessful;
          totFailure += s.numberOfFailed;
        });

        this.totalStats.setNumberOfFailed(totFailure);
        this.totalStats.setNumberOfSuccessful(totSuccess);

        // Hide warning and loading message
        this.hiddenAlertTableContentIsLoading = true;
        this.hiddenAlertDanger = true;

      },
      error => {
        this.hiddenAlertTableContentIsLoading = true;
        this.hiddenAlertDanger = false;
      });

    // Show Toast message whenever data are refreshed. Hide the Toast after 3 seconds
    this.openSnackBar();
  }

  openAuthorityMain(): void {
    this.router.navigate([ 'authority', this.selectedAuthority ]);
  }

  openSnackBar(): void {
    this.snackBar.open('Data has been updated', 'Close', {
      duration: 3000
    });
  }
}
