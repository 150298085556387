import {MessageModel} from './message.model';
import {Polyline} from './polyline.model';
import {SupportTrafficLightModel} from './support-traffic-light.model';

export class PopupTrafficLightModel {

  readonly details: SupportTrafficLightModel;
  readonly type: string;

  constructor(details: SupportTrafficLightModel) {
    this.details = details;
    this.type = 'traffic_light';

  }

}
