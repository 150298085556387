export class PolylineTypeLegend {

  type: string;
  name: string;
  styleName: string;
  disabled: boolean;

  constructor(type: string, name: string, styleName: string, disabled: boolean) {
    this.type = type;
    this.name = name;
    this.styleName = styleName;
    this.disabled = disabled;
  }

}
