<div class="time-filter-container">
  <div class="time-filter">
    <h1 class="title">
      {{title}}
    </h1>

    <div class="buttonsContainer">
      <button [disabled]=" isLoading || selected === value " [disableRipple]="true" mat-button
              [class]="'filter' + ( (isLoading || selected === value) ? ' filter-selected' : '' ) "
              *ngFor="let value of values; let i = index" (click)="selectValue(i)">
        {{getName(value)}}
      </button>

      <button mat-button
              matTooltip="Refresh page content"
              matTooltipPosition="below"
              matTooltipHideDelay="100"
              matTooltipClass="mat-tooltip"
              [disabled]=" isLoading "
              class="refresh" (click)="refreshData()">
        <img src="../../assets/img/arrow-clockwise-white.svg" alt=""/>
      </button>
    </div>
  </div>
</div>
