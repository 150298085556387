import {FailureMessageModel} from './failuremessage.model';

export class FailureMessageVisualizationModel {

  readonly failureMessages: FailureMessageModel[];
  readonly situationRecordId: string;
  totalFailureMessages: number;
  visible: boolean;

  constructor(failureMessages: FailureMessageModel[], situationRecordId: string, totalFailureMessages: number) {
    this.failureMessages = failureMessages;
    this.situationRecordId = situationRecordId;
    this.totalFailureMessages = totalFailureMessages;
    this.visible = true;
  }

  getSituationRecordId(): string {
    return this.situationRecordId;
  }

  getFailureMessages(): FailureMessageModel[] {
    return this.failureMessages;
  }

  getTotalFailureMessages(): number {
    return this.totalFailureMessages;
  }

  setVisible(visible: boolean): void {
    this.visible = false;
  }

}

