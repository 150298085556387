import {MessageModel} from './message.model';
import {PoizoneModel} from './poizone.model';

export class MessageResponseModel {

  readonly authorityName: string;
  readonly messages: MessageModel[];
  readonly zones?: Map<string, PoizoneModel>;
  readonly inputFormat?: string;

  constructor(authorityName: string, messages: MessageModel[], zones: Map<string, PoizoneModel> = null,  inputFormat: string = null) {
    this.authorityName = authorityName;
    this.messages = messages;
    this.zones = zones;
    this.inputFormat = inputFormat;
  }
}
