<div class="wrapper container">
  <div class="imgcontainer">
    <img src="../../assets/img/loginHeader.jpg" width="100%" height="200pt" alt="">
  </div>

  <form method="post">

    <div class="imgcontainer" style="margin-top: 10pt">
      <img src="../../assets/img/M_logo.png" class="imgLogo display-inline-left" alt="">
      <h1 class="display-inline-right">C-ITS Dashboard</h1>
    </div>

    <div class="formFieldContainer">

      <mat-form-field class="form-input">
        <mat-label>Insert your Username</mat-label>
        <input matInput placeholder="e.g. Paolo" type="text" [(ngModel)]="username" name="uname" required (input)="validateUsername($event)">
        <mat-icon matPrefix>perm_identity</mat-icon>
      </mat-form-field>

      <mat-form-field class="form-input">
        <mat-label>Insert your Password</mat-label>
        <input matInput [type]="showPassword ? 'text' : 'password'" placeholder="Enter Password"
               [(ngModel)]="password" name="psw"
               required (input)="validatePassword($event)">
        <mat-icon matPrefix>lock</mat-icon>
        <mat-icon matSuffix (click)="showHidePassword()">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>

      </mat-form-field>

      <button mat-button type="submit" (click)="doLogin()" [disabled]="btnLoginDisabled" [class.spinner]="btnLoadingSpinnerVisible">
        {{ btnLoginText }}
      </button>

      <div class="alert alert-danger alertStyle" role="alert" *ngIf="showAlertInvalidCredential">
        Username or password not valid!
      </div>

      <div class="alert alert-danger alertStyle" role="alert" *ngIf="showAlertError">
        Username or password not valid!
      </div>

    </div>

  </form>


  <footer class="footer footer-style">
    <div class="wrapper container">

      <div class="horizontallyCenterContainer">
        <hr />
        <p>&copy; Movalia S.r.l. Via degli Artigiani Sud, 19, Egna (BZ) Italy 39044 - P.Iva 02385970229</p>
        <p>C—ITS Dashboard Version 0.7.0</p>
      </div>
    </div>
  </footer>
</div>

