import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Datex2parseDataService} from '../service/datex2parse-data.service';
import {MessageModel} from '../model/message.model';
import {EventMapComponent} from '../event-map/event-map.component';
import {PolylineTypeLegend} from '../model/polyline-type-legent.model';
import {PoizoneModel} from '../model/poizone.model';
import {MessageResponseModel} from '../model/message.response.model';
import {StatusOfDeliveryModel} from '../model/status-of-delivery.model';
import {MapData} from '../model/map.data.model';
import {Coordinate} from '../model/coordinate.model';
import {TestInformationModel} from '../model/test.information.model';
import {UtilityFunctions} from "../service/utility-functions";

@Component({
  selector: 'app-datex2-manual',
  templateUrl: './datex2-parse-data.component.html',
  styleUrls: ['./datex2-parse-data.component.css']
})
export class Datex2ParseDataComponent extends UtilityFunctions implements OnInit {

  @ViewChild(EventMapComponent) eventMapComponent: EventMapComponent;
  @ViewChild('pageTop', { read: ElementRef }) pageTop: ElementRef;
  @ViewChild('messages', { read: ElementRef }) messagesContainer: ElementRef;

  // URL Parameters -------------------------------------------------
  inputFormat: string;
  // 'true': if testing dynamic data for the useCase, 'false': if testing static data; 'null': for useCases that only have one type of data
  dataType: string;
  authorityShortName: string;
  useCase: string;
  // ----------------------------------------------------------------

  dataTypeTitle: string;

  inputData: string;
  totResponses: number;

  showScrollToTopButton = false;
  showChildren: boolean;
  showWarningMsg: boolean;
  isBtnParseDataDisabled: boolean;
  isBtnLoadingSpinnerVisible: boolean;
  isMapVisible: boolean;

  btnParseMessage: string;

  parsedElements: ParsedElement[];

  // Button within the legend component
  polylineTypeLegends: PolylineTypeLegend[];

  constructor(private router: Router, private activeRoute: ActivatedRoute,
              private datex2manualService: Datex2parseDataService)
  {
    super();

    // Note: force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {

    this.authorityShortName = this.activeRoute.snapshot.params.authorityShortName;
    this.useCase = this.activeRoute.snapshot.params.useCase;
    this.inputFormat = this.activeRoute.snapshot.params.inputFormat;
    this.inputFormat = this.activeRoute.snapshot.params.inputFormat;
    this.dataType = this.activeRoute.snapshot.params.dataType;

    this.dataTypeTitle = this.setTitleDataType(this.dataType);

    this.parsedElements = [];
    this.polylineTypeLegends = [];

    this.totResponses = 0;
    this.inputData = '';
    this.showChildren = true;
    this.showWarningMsg = false;
    this.isBtnParseDataDisabled = true;
    this.isBtnLoadingSpinnerVisible = false;

    this.btnParseMessage = 'Parse ' + this.inputFormat;

    // It must be true when the page is loaded, otherwise the component "eventMapComponent" does not get initialized
    this.isMapVisible = true;


  }

  updateInputData(val: string): void {
    this.inputData = val;
    this.isBtnParseDataDisabled = !this.validateInput();
  }

  validateInput(): boolean {
    const regxSpace = new RegExp(' ', 'g');
    const regxReturn = new RegExp('\n', 'g');

    let input = this.inputData.replace(regxSpace, '');
    input = input.replace(regxReturn, '');

    this.showWarningMsg = (input.length === 0);

    // if the input size is 0, and thus 'showWarningMsg' is true, then the validation was NOT successful
    return !this.showWarningMsg;
  }

  parseData(): void {

    let description = '';
    const successDesc = 'Successfully parsed message';
    const errorDesc = 'Error while parsing message';
    this.totResponses = 0;
    this.parsedElements = [];
    this.polylineTypeLegends = [];
    this.isMapVisible = false;
    this.showScrollToTopButton = false;

    if (this.validateInput()) {

      this.toggleButtonEnabled(true);

      this.datex2manualService.postTestData(this.authorityShortName, this.inputFormat, this.useCase, this.inputData).subscribe(res =>
        { // Note: when server responds with OK, and response content is APPLICATION_JSON
          console.log('Successful response');
          this.parsedElements = this.manageResponseBody(res, successDesc, errorDesc, true, true);
          this.toggleButtonEnabled(false);
        },
        error => {

          // TODO: migliorare e semplificare....

          console.log('Error status: ' + error.status);
          if (error.status === 200) { // Note: when server responds with OK, and response content is TEXT_PLAIN
            this.parsedElements = this.manageResponseBody(error.error.text, successDesc, errorDesc, true, true);
          }
          else { // Note: when server responds with ERROR (401, 404, 5xx)
            if (error.error != null) {

              if (error.error.text != null) {
                // e.g., when trying to parse a RWW message not correctly formatted or without the mandatory fields
                description = '[ERROR] ' + error.error.text;
              }
              else if (error.error.message != null) {
                // e.g., when trying to parse a IVIM message not correctly formatted
                description = '[ERROR] ' + error.error.message;
              }
              else if (error.error.messages != null) {

                // e.g., when trying to parse a HLN-VRU, or when POI contains errors
                if (error.error.messages instanceof Array ) {

                  error.error.messages.forEach(msg => {
                    description = '';

                    if (msg.messageContent != null ) {

                      // TODO: andrebbe gestito controllando i singolo messageContent per controllare se sono
                      //  effettivamente errori o se ci sono info che possono essere visualizzate su mappa
                      msg.messageContent.forEach(msgContent => {
                        description += '[ERROR] ' + msgContent + '\n';
                      });
                    }
                    else {
                      description = '[ERROR] ' + msg.result;
                    }

                    this.parsedElements.push(new ParsedElement(errorDesc, description, false, true, null));
                    description = '';
                  });
                }
                else {
                  description = '[ERROR] ' + error.error.messages;
                }
              }
              else {
                description = '';
                this.parsedElements = this.manageResponseBody(error.error, successDesc, errorDesc, false, true);
              }
            }
            else {
              description = '[ERROR] HttpResponse does not contain parameter "error"';
            }
          }

          if (description.length > 0) {
            this.parsedElements.push(new ParsedElement(errorDesc, description, false, true, null));
          }
          this.totResponses = this.parsedElements.length;
          this.toggleButtonEnabled(false);

        }
      );
    }
  }


  private setTitleDataType(dataType: string): string {
    if ( dataType ) {
      if (dataType === 'dynamic') {
        return ' - Dynamic Data';
      }
    }
  }

  private toggleButtonEnabled(status: boolean): void {

    if (status) {
      this.isBtnParseDataDisabled = true;
      this.isBtnLoadingSpinnerVisible = true;
      this.btnParseMessage = '';
    }
    else {
      this.isBtnParseDataDisabled = false;
      this.isBtnLoadingSpinnerVisible = false;
      this.btnParseMessage = 'Parse ' + this.inputFormat;
    }
  }

  toggleElement(element): void {
    element.visible = !element.visible;
  }

  private manageResponseBody(response: any, successDesc: string, errorDesc: string, success: boolean, visible: boolean): ParsedElement[] {
    const pe: ParsedElement[] = [];
    const messagesModel: MessageModel[] = [];
    let zones = new Map<string, PoizoneModel>();
    // const listSsemExtraData: SSEMExtraData[] = [];
    let parsedElementTitle = successDesc;

    if (response != null) {

      if (typeof response === 'object')
      { // use .length to differentiate between arrays and actual objects
        const responseModel: MessageResponseModel = (response as MessageResponseModel); // TODO: add try catch

        if (responseModel != null) {
          const messagesReceived = responseModel.messages;

          if (messagesReceived !== undefined) {
            let cnt = 1;
            const msgSuccess = success;
            let messageContentList: string[] = [];
            let description = '';
            let messageType = '';
            let messageClass = '';
            let eventLocation: number[] = [];

            messagesReceived.forEach(item => {
              success = msgSuccess;
              parsedElementTitle = successDesc;

              if (item !== null) {

                messageContentList = item.messageContent;

                messageContentList.forEach(msgContent => {
                  description = msgContent != null ? msgContent : 'null (The received Message Content is not valid)';

                  // Description either contains the "error message" or the "decoded denm"
                  if (description.toString().substring(0, 4) === 'null' // denm
                    || description.toString().substring(0, 5) === 'error') // ivim
                  {
                    success = false;
                    eventLocation = null;
                    parsedElementTitle = errorDesc;
                    description = '[ERROR] ' + description;
                    pe.push(new ParsedElement(parsedElementTitle + ' ' + cnt++, description, success, visible, eventLocation));

                  }
                  else if (description.toString().substring(0, 7) === 'warning') // spatem
                  {
                    success = false;
                    eventLocation = null;
                    parsedElementTitle = errorDesc;
                    description = '[WARNING] ' + description;
                    pe.push(new ParsedElement(parsedElementTitle + ' ' + cnt++, description, success, visible, eventLocation));
                  }
                  else { // Message contains the expected data from MessageModel

                    const mapData = item.display;
                    messageType = mapData.messageType;

                    if ((messageType === 'SPATEM_MAPEM' || messageType === 'SSEM') && cnt === 2) { // SPATEM
                      success = true;
                      eventLocation = null;
                      parsedElementTitle = successDesc;
                    }
                    else if (messageType === 'POI') {
                      const stalls = this.extractStallsFromPOIMessage(item);
                      stalls.forEach(stall => messagesModel.push(stall));
                      zones = this.handleMessageZones(zones, item.id, item.optionalParams);
                    }
                    else {

                      messagesModel.push(new MessageModel(cnt.toString(), cnt.toString(), [description],
                        this.authorityShortName, item.generationTime, null, mapData, item.optionalParams));

                      if (mapData.referencePoint) {
                        // Add link to center map on the specified event
                        eventLocation = [mapData.referencePoint.longitude, mapData.referencePoint.latitude];
                      }

                      // If the usecase is SSEM, check if there are extra information
                      // if (parsedDataResponse.ssemExtraData) {
                      //   listSsemExtraData.push(parsedDataResponse.ssemExtraData);
                      // }
                    }

                    // Update map legend
                    [messageClass, this.polylineTypeLegends] = this.manageMapLegend(this.useCase);

                    // Updated the list of messages
                    pe.push(new ParsedElement(parsedElementTitle + ' ' + cnt++, description, success, visible, eventLocation));
                  }

                });
              }
            });

            // Update the map with the new data (clean it if no polyline are returned)
            // this.populateMap(messagesModel, messageType, listSsemExtraData);
            this.populateMap(messagesModel, messageType, zones);

            // Hide/show the map if messages are present
            this.isMapVisible = (messagesModel.length > 0);
          }
          else { // Object
            parsedElementTitle = success ? successDesc : errorDesc;
            pe.push(new ParsedElement(parsedElementTitle, JSON.stringify(response, undefined, 2), success, visible, null));
          }
        }
        else { // Unable to convert the incoming object to MessageResponseModel
          parsedElementTitle = errorDesc;
          pe.push(new ParsedElement(parsedElementTitle, response, false, true, null));

        }
      }
      else {
        // Other type (Note: this part is triggered when parsing APR as TJA)
        // e.g., when trying to parse a RWW message using OR as 'this.useCase' (The posted XML can't be converted)

        parsedElementTitle = success ? successDesc : errorDesc;
        pe.push(new ParsedElement(parsedElementTitle, response, success, visible, null));
      }
    }

    this.totResponses = pe.length;
    this.showScrollToTopButton = (messagesModel.length > 0 || pe.length > 0);

    return pe;
  }

  private populateMap(msg: MessageModel[], messageType: string, zones: Map<string, PoizoneModel>): void {

    this.eventMapComponent.clearMap();
    this.eventMapComponent.isMapInLocationOnlyMode = false; // change the map's mode
    this.eventMapComponent.events = msg; // update the list of events
    this.eventMapComponent.zones = zones;
    this.eventMapComponent.setSelectedRadius(messageType); // parameter used to set the event area radius on the map
    // this.eventMapComponent.setSsemExtraData(listSsemExtraData); // parameters used only for SSEM usecases
    this.eventMapComponent.populateMap(); // show the updated events on the map
  }

  private centerMapOnEvent(location: number[]): void {
    if (location.length === 2) {
      this.eventMapComponent.centerMapOnLocation(location);
      // Scroll the map into view
      this.eventMapComponent.elem.nativeElement.scrollIntoView({
        behavior: 'smooth'
      });

    }
    else {
      console.log('Error: invalid location.');
    }
  }

  private handleMessageZones(zones: Map<string, PoizoneModel>, msgId: string,
                             optionalParams: Map<string, string>): Map<string, PoizoneModel>
  {

    if (optionalParams !== null) {

      const zoneString = optionalParams['zone'];

      if (zoneString){
        // Convert string zone to list of zones
        zones[msgId] = JSON.parse(zoneString);

      }

      const allZones = optionalParams['all_zones'];

      if (allZones){
        const listOfZones = JSON.parse(allZones);
        // console.log(listOfZones);

        if (listOfZones) {
          listOfZones.forEach(zone => {
            zones[zone['zoneId']] = zone;
          });
        }
      }

    }

    return zones;
  }

  scrollToTop(): void {
    try {
      this.pageTop.nativeElement.scrollIntoView(true);
    }
    catch (err) { }
  }

  scrollToMessages(): void {
    try {
      this.messagesContainer.nativeElement.scrollIntoView(true);
    }
    catch (err) { }
  }

  /** Only needed for POI messages. Extract from optionalParams the list of sensors (parking locations)
   * Create a new MessageModel for each sensor */
  private extractStallsFromPOIMessage(msg: MessageModel): MessageModel[] {

    let msgs = [ msg ];

    if (msg.optionalParams !== null) {
      if ( msg.optionalParams['sensors'] ){

        const sensors = JSON.parse(msg.optionalParams['sensors']);
        if ( sensors.length > 0 ){

          msgs = [];
          for ( const s of sensors ){

            const optionalParams: Map<string, string> = new Map<string, string>();
            optionalParams['id'] = s.id;
            optionalParams['status'] = s.sensorStatusEnum;
            optionalParams['type'] = s.sensorTypeEnum;

            const m = new MessageModel(s.id, s.id, [], this.authorityShortName, msg.generationTime, new StatusOfDeliveryModel(false, false),
              new MapData(new Coordinate(s.position.latitude, s.position.longitude), [], 'POI', ''), optionalParams);

            msgs.push(m);

          }
        }
      }
    }
    return msgs;

  }
}


class ParsedElement {
  title: string;
  description: string;
  success: boolean;
  visible: boolean;
  location: number[];

  constructor(title: string, description: string, success: boolean, visible: boolean, location: number[]) {
    this.title = title;
    this.description = description;
    this.success = success;
    this.visible = visible;
    this.location = location;
  }

}
