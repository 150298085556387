import {MessageModel} from './message.model';
import {Polyline} from './polyline.model';

export class PopupModel {

  readonly details: MessageModel;
  readonly type: 'location' | 'lane' | 'traffic_light' | 'polyline';

  // Mandatory if type = 'polyline'
  polylineEncoded?: string;
  totalTraces?: number;
  traceLength?: string;

  // Mandatory if type = 'lane'
  polyline?: Polyline;


  constructor(details: MessageModel, type: 'location' | 'lane' | 'traffic_light' | 'polyline') {
    this.details = details;
    this.type = type;

    this.polylineEncoded = null;
    this.totalTraces = null;
    this.totalTraces = null;

    this.polyline = null;
  }

}
