import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {MessageResponseModel} from '../model/message.response.model';
import {AuthenticationService} from './authentication.service';
import {UtilityFunctions} from './utility-functions';

@Injectable({
  providedIn: 'root'
})
export class MessagesService extends UtilityFunctions {

  constructor(private httpClient: HttpClient,
              private authenticationService: AuthenticationService) {
    super();
  }

  public getMessages(authorityShortName: string, usecase: string,
                     lastDate: string, customHeaders: Map<string, string>): Observable<MessageResponseModel> {
    const url = environment.baseUrl + 'users/me/authorities/' + authorityShortName +
      '/useCases/' + usecase + '/messages?lastDate=' + lastDate;

    // performs a http GET request to the specified URL
    return this.httpClient.get<MessageResponseModel>(url, {
      headers: this.HttpRequestHeaders(this.authenticationService.idToken)
    });
  }
}
