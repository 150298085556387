<ng-sidebar-container>
  <ng-sidebar #sidebar [dock]="true" [dockedSize]="'70'" [opened]="true" [position]="'left'"
              [animate]="true" mode="push">

    <div class="sidebar-header">

      <div *ngIf="!sidebar._isDocked" style="display: inline-block;">
        <img src="/assets/img/M_logo.png" height="55" width="50" alt="M" (click)="openHomePage()">
        <span><h3 (click)="openHomePage()"> C-ITS Server</h3></span>
        <img class="collapse" (click)="sidebar.close()"
             src="/assets/img/cancel.svg" alt="X" height="50" width="30">
      </div>

      <img class="open" *ngIf="sidebar._isDocked" (click)="sidebar.open()" src="assets/img/three-dots-vertical.svg" height="30" width="50" alt="">
    </div>

    <div class="sidebar-body">
      <!-- Note: npm i ngx-scrollbar@7.5.3  +  npm i @angular/cdk -->
      <ng-scrollbar>
        <app-sidebar-item
          *ngFor="let item of items" [itemInfo]="item" [compact]="sidebar._isDocked">
        </app-sidebar-item>
      </ng-scrollbar>
    </div>
  </ng-sidebar>

  <!-- Page content -->
  <ng-content ng-sidebar-content>
  </ng-content>
</ng-sidebar-container>

