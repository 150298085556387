import {IntersectionStatesModel} from './intersection-states.model';

export class SupportTrafficLightModel {

  id: string;
  eventState: string;

  coordinates: number[];
  intersectionStates: IntersectionStatesModel;
  currentStateTimeSpeed: number;

  nowTimeAsMoy: number;
  intersectionMoy: number;
  likelyChangeTime: number;
  timeToNextState: number;

  constructor(id: string, eventState: string, coordinates: number[], intersectionStates: IntersectionStatesModel,
              currentStateTimeSpeed: number, nowTimeAsMoy: number, intersectionMoy: number, likelyChangeTime: number)
  {
    this.id = id;
    this.eventState = eventState;
    this.coordinates = coordinates;
    this.intersectionStates = intersectionStates;
    this.currentStateTimeSpeed = currentStateTimeSpeed;
    this.nowTimeAsMoy = nowTimeAsMoy;
    this.intersectionMoy = intersectionMoy;
    this.likelyChangeTime = likelyChangeTime;

    this.timeToNextState = (intersectionMoy + likelyChangeTime) - nowTimeAsMoy;
  }

  public getNowTimeSeconds(): number {
    return Math.floor(this.nowTimeAsMoy / 10); // intersectionMoy is stored as 1/10th of seconds
  }

  public getIntersectionMoySeconds(): number {
    return Math.floor(this.intersectionMoy / 10); // intersectionMoy is stored as 1/10th of seconds
  }

  public getLikelyChangeTimeSeconds(): number {
    return Math.floor(this.likelyChangeTime / 10 ); // likelyChangeTime is stored as 1/10th of seconds
  }

  public getTimeToNextStateSeconds(): number {
    return Math.floor(this.timeToNextState / 10 ); // timeToNextState is stored as 1/10th of seconds
  }
}
