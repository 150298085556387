import {TimeFilter} from './model/time.filter.model';

export function decodePolyline(str: string, precision: number): number[][] {
  let index = 0;
  let lat = 0;
  let lng = 0;
  const coordinates = [];
  let shift = 0;
  let result = 0;
  let byte = null;
  let latitudeChange;
  let longitudeChange;
  const factor = Math.pow(10, precision || 6);

  // Coordinates have variable length when encoded, so just keep
  // track of whether we've hit the end of the string. In each
  // loop iteration, a single coordinate is decoded.
  while (index < str.length) {

    // Reset shift, result, and byte
    byte = null;
    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      // tslint:disable-next-line:no-bitwise
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    // tslint:disable-next-line:no-bitwise
    latitudeChange = ((result & 1) ? ~(result >> 1) : (result >> 1));

    shift = result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      // tslint:disable-next-line:no-bitwise
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    // tslint:disable-next-line:no-bitwise
    longitudeChange = ((result & 1) ? ~(result >> 1) : (result >> 1));

    lat += latitudeChange;
    lng += longitudeChange;

    coordinates.push([lat / factor, lng / factor]);
  }

  return coordinates;
}

export function getFormattedDate(date: string): string {
  return new Date(date).toLocaleDateString('it-IT'); // format: dd/MM/yyyy, HH:mm:ss
}

export function getFormattedDateTimeFilter(date: TimeFilter): string {
  return new Date(date).toLocaleDateString('it-IT'); // format: dd/MM/yyyy, HH:mm:ss
}
