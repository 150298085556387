import {Component, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {MenuItem} from './model/menu.item.model';
import {Title} from '@angular/platform-browser';
import {AuthoritiesService} from './service/authorities.service';
import {AuthenticationService} from './service/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PermissionResponseModel} from './model/permission.response.model';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'C-ITS Dashboard';
  pageTitle = 'C-ITS Dashboard';
  menuItems: MenuItem[] = [];
  childItems: MenuItem[] = [];

  isLoggedIn: boolean;

  loadAuth: Subscription;

  // This variable is used to pass the selected 'dateFilter' between pages
  private dateFilterParam: number;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private authoritiesService: AuthoritiesService,
              private authenticationService: AuthenticationService,
              private titleService: Title)
  {
    this.titleService.setTitle(this.pageTitle);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {

    this.authenticationService.isUserLoggedIn().subscribe(s => {
      this.isLoggedIn = s;

      /* This component is used from all pages.
      This means that when the login loads, ngOnInit is called.
      However, the isLoggedIn is false and the data download is correctly ignored
      (and are load by "setIsLoggedIn" called when the login is successful)
      However, if the user refresh the page from the browser,
      both this and "setIsLoggedIn" are called. With !s we block the statement in order to only load data from "setIsLoggedIn"*/
      if ( this.isLoggedIn && !s ) {
        this.loadAuthorities();
      }

    });
  }

  loadAuthorities(): void {

    this.menuItems = [
      { // add menu item to the page
        name: 'Dashboard', compactName: undefined,
        route: ['dashboard'],
        childItems: [], icon: 'dashboard'
      },
      { // Always display "logout" button
        name: 'Logout', compactName: null, icon: 'box-arrow-left', route: [ 'logout' ], childItems: []
      }
    ];

    // Get the list of authorities --------------------------------------------
    this.authoritiesService.getAuthorities().pipe(
      map(permissionResponse => {
        return permissionResponse;
      })
    ).subscribe(permissionResponse => {
      this.manageAuthoritiesMenu(permissionResponse);
    });
    // ------------------------------------------------------------------------
  }

  manageAuthoritiesMenu(permissionResponse: PermissionResponseModel): void {

    // Remove button "logout" from the menu
    this.menuItems.pop();

    permissionResponse.permissions.forEach(p => {

      this.childItems = [];

      // store each useCase for the specified authority
      p.useCases.forEach(uc => {
        this.childItems.push({
          name: uc.name, compactName: uc.compactName, icon: null, route: [ 'authority', p.compactName, uc.name ], childItems: []
        });
      });

      // create a menu item for each authority
      this.menuItems.push({
        name: p.name, compactName: p.compactName, icon: null, route: [ 'authority', p.compactName ], childItems: this.childItems
      });
    });

    // Add "logout" button back at the end of the sidebar menu
    this.menuItems.push({
      name: 'Logout', compactName: null, icon: 'box-arrow-left', route: [ 'logout' ], childItems: []
    });

  }

  setIsLoggedIn(val: boolean): void {

    this.isLoggedIn = val;
    this.childItems = [];
    this.menuItems = [];

    if ( val ) {

      this.loadAuthorities();

    }
    else {
      this.childItems = [];
      this.menuItems = [];
    }

  }

  setDateFilterParam(value: number): void {
    this.dateFilterParam = value;
  }

  getDateFilterParam(): number {
    return this.dateFilterParam;
  }
}
