import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {FailureMessageResponseModel} from '../model/failuremessage.response.model';
import {UtilityFunctions} from './utility-functions';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class FailureMessagesService extends UtilityFunctions {

  constructor(private httpClient: HttpClient,
              private authenticationService: AuthenticationService) {
    super();
  }

  public getFailureMessages(authorityShortName: string, usecase: string,
                            lastDate: string, lastNMessages: number): Observable<FailureMessageResponseModel>
  {
    const url = environment.baseUrl + 'users/me/authorities/' + authorityShortName +
      '/useCases/' + usecase + '/failures/last?lastDate=' + lastDate + '&lastNMessages=' + lastNMessages;

    // performs a http GET request to the specified URL
    return this.httpClient.get<FailureMessageResponseModel>(url, { headers: this.HttpRequestHeaders(this.authenticationService.idToken) });
  }


  public downloadFailureMessages(authorityShortName: string, usecase: string, lastDate: string): Observable<boolean> {

    const url = environment.baseUrl + 'users/me/authorities/' + authorityShortName +
      '/useCases/' + usecase + '/failures?lastDate=' + lastDate;

    const filename = 'failure_messages_' + authorityShortName + '_' + usecase + '_' + lastDate + '.csv';

    const p = this.httpClient.get(url, { headers: this.HttpRequestHeaders(this.authenticationService.idToken) })
      .toPromise().then(
      (response: FailureMessageResponseModel) => {

        if (response !== null) {

          const binaryData = [];
          let i = 1;

          // Set file content header
          binaryData.push(';Message identifier;Date;Reason of failure;\n');

          if (response.failureMessages !== null && response.failureMessages !== undefined) {

            // add a row for each failure message
            response.failureMessages.forEach(group => {
              group.forEach(item => {
                binaryData.push(i + ';' + item.situationRecordID + ';' + item.date + ';' + item.reasonOfFailure + ';\n');
                i += 1;
              });
            });

            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: 'text/csv'}));
            if ( filename ) {
              downloadLink.setAttribute('download', filename);
            }

            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();

          }
        }

        for (let i = 0; i < 50000; i++) {
          console.debug(i);
        }
        return true;

      },
      error => {
        // TODO: handle error
        console.log('Unexpected error: ' + error);
        return false;
      });

    return from(p);
  }

}
