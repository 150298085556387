import {Coordinate} from './coordinate.model';
import {Polyline} from './polyline.model';

export class MapData {

  referencePoint: Coordinate;
  polylines: Polyline[];
  messageType: 'DENM' | 'IVIM' | 'SPATEM/MAPEM' | 'POI' | 'CAM';
  description?: string;

  constructor(referencePoint: Coordinate, polylines: Polyline[],
              messageType: 'DENM' | 'IVIM' | 'SPATEM/MAPEM' | 'POI' | 'CAM' , description: string)
  {
    this.referencePoint = referencePoint;
    this.polylines = polylines;
    this.messageType = messageType;
    this.description = description;
  }
}

