import {Component, Input, OnInit} from '@angular/core';
import {DashboardCityData} from '../model/dashboard.city.data.model';
import {DashboardChartModel} from '../model/dashboard.chart.model';

@Component({
  selector: 'app-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.css']
})
export class DashboardChartComponent implements OnInit {

  @Input() authorityStats: DashboardCityData;
  @Input() view: number[];

  colorScheme = {
    domain: ['#009933', '#CC3300' ]
  };

  constructor() { }

  ngOnInit(): void { }

  getResults(): DashboardChartModel[] {

    let totFailed = 0;
    let totSuccessfull = 0;
    let cityName = '';

    if (this.authorityStats != null) {
      totFailed = this.authorityStats.numberOfFailed;
      totSuccessfull = this.authorityStats.numberOfSuccessful;
      cityName = this.authorityStats.authorityName;
    }

    const results: DashboardChartModel[] = [];
    const successfulCalls: DashboardChartModel = {
      name: 'Successful',
      value: totSuccessfull,
      extra: {
        code: 'success-' + cityName
      }
    };

    const failedCalls: DashboardChartModel = {
      name: 'Failed',
      value: totFailed,
      extra: {
        code: 'failed' + cityName
      }
    };

    results.push(successfulCalls);
    results.push(failedCalls);

    return results;

  }

  percentageFormatting(data: string): string {

    // format percentage, only use two decimal number
    if ( !isNaN(Number(data)) ) {
      return parseFloat(data).toFixed(2);
    }

    return data;
  }

}
