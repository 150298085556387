import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {StatisticsResponseModel} from '../model/statistics.response.model';
import {UtilityFunctions} from './utility-functions';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService extends UtilityFunctions {

  constructor(private httpClient: HttpClient,
              private authenticationService: AuthenticationService) {
    super();
  }

  public getStatisticsMessages(lastDate: string): Observable<StatisticsResponseModel> {
    const url = environment.baseUrl + 'users/me/dashboard/statistics?lastDate=' + lastDate;

    // performs a http GET request to the specified URL
    return this.httpClient.get<StatisticsResponseModel>(url, { headers: this.HttpRequestHeaders(this.authenticationService.idToken) });
  }

  public getAuthorityStatisticsMessages(authorityShortName: string, // msgType: string,
                                        useCase: string, lastDate: string): Observable<StatisticsResponseModel> {
    const url = environment.baseUrl + 'users/me/dashboard/statistics/' + authorityShortName
      + '/useCases/' + useCase
      + '?lastDate=' + lastDate;

    // performs a http GET request to the specified URL
    return this.httpClient.get<StatisticsResponseModel>(url, { headers: this.HttpRequestHeaders(this.authenticationService.idToken) });
  }
}
