import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {AuthenticationService} from '../service/authentication.service';
import { filter } from 'rxjs/operators';
import {AppComponent} from '../app.component';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username = '';
  password = '';

  navigationUrl = 'dashboard';
  btnLoginText = 'Login';
  showAlertInvalidCredential = false;
  showAlertError = false;
  btnLoginDisabled = true;
  btnLoadingSpinnerVisible = false;

  validUsername = false;
  validPassword = false;
  showPassword = false;

  waitingTimer: Subscription;

  constructor(private router: Router, private activeRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private appComponent: AppComponent)
  { }

  ngOnInit(): void {

    this.showAlertError = false;

    // Get the URL active before Login Component was called (for instance, used when the user refresh the browser)
    this.router.events
      .pipe( filter(event => event instanceof NavigationStart) )
      .subscribe((event: NavigationStart) => {
          this.navigationUrl = event.url;
        },
        error => {
          this.showAlertError = true;
          this.showAlertInvalidCredential = false;
        });

    this.authenticationService.isUserLoggedIn().subscribe(isLoggedIn => {
        this.appComponent.setIsLoggedIn( isLoggedIn ); // update appComponent in order to remove the sidebar if the login page is shown

        if ( isLoggedIn ) {
          console.log('Already logged in!');

          this.navigateToUrlAndQueryParams();
        }
      },
      error => {
        this.showAlertError = true;
        this.showAlertInvalidCredential = false;
      });
  }

  doLogin(): void{
    this.showAlertInvalidCredential = false;
    this.showAlertError = false;

    this.btnLoginDisabled = true;
    this.btnLoginText = '';
    this.btnLoadingSpinnerVisible = true;

    this.authenticationService.login(this.username, this.password).subscribe(authSuccessful =>
    {
      if (authSuccessful) {

        /* Note: without this timer, it might happen that a correct AWS token generates a 401 unauthorized error on the server
        * when the dashboard page calls the backend API.
        * The error on the server is: "com.auth0.jwt.exceptions.InvalidClaimException: The Token
        *  can't be used before Fri Jan 28 09:28:25 CET 2022"
        * and is thrown by: DecodedJWT decodedJWT = verifier.verify(token); in class JWTUtil */
        this.waitingTimer = interval(2000).subscribe(val => {
          this.router.navigate(['dashboard']);
          this.appComponent.setIsLoggedIn(true);

          this.manageBtnLogin();

          this.waitingTimer.unsubscribe();
        });

      }
      else {
        this.showAlertInvalidCredential = true;
        this.showAlertError = false;

        this.manageBtnLogin();
      }

    });
  }

  validateUsername(event: any): void {
    this.validUsername = false;
    this.btnLoginDisabled = true;
    const re = new RegExp(' ', 'g');

    let username = event.target.value;
    username = username.replace(re, '');

    if (username.length > 0) {
      this.validUsername = true;

      if (this.validPassword) {
        this.btnLoginDisabled = false;
      }
    }
  }

  validatePassword(event: any): void {
    this.validPassword = false;
    this.btnLoginDisabled = true;

    const password = event.target.value;

    if (password.length > 0) {
      this.validPassword = true;

      if (this.validUsername) {
        this.btnLoginDisabled = false;
      }
    }
  }

  manageBtnLogin(): void {
    this.btnLoginDisabled = false;
    this.btnLoginText = 'Login';
    this.btnLoadingSpinnerVisible = false;
  }

  // Check if the URL contains query params. If it does, extract the 'dateFilter' and propagate it to
  navigateToUrlAndQueryParams(): void {
    if (this.navigationUrl === '/' || this.navigationUrl.indexOf('login') > -1) {
      this.navigationUrl = 'dashboard';
    }

    this.router.navigate([ this.navigationUrl ]);
  }

  showHidePassword(): void {
    this.showPassword = !this.showPassword;
  }

}
