export class StatusOfDeliveryModel {

  readonly IP: boolean; // e.g., TIM, VODAFONE ecc...
  readonly G5: boolean; // e.g., RSU

  constructor(IP: boolean, G5: boolean) {
    this.IP = IP;
    this.G5 = G5;
  }
}
