import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {UtilityFunctions} from './utility-functions';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class Datex2parseDataService extends UtilityFunctions{

  constructor(private httpClient: HttpClient,
              private authenticationService: AuthenticationService) {
    super();
  }

  public postTestData(authorityShortName: string, format: string, useCase: string, body: string): Observable<JSON>{

    const citsUseCase = this.convertUseCaseToCITSUseCase(authorityShortName, useCase);
    const url = environment.baseUrl + 'translation/' + authorityShortName + '/' + format + '/' + citsUseCase + '/test';
    let contentType = 'application/json';

    if ( format === 'DATEX2' ){
      contentType = 'application/xml';
    }
    else if ( format === 'JSON' ) {
      contentType = 'application/json';
    }
    else if ( format === 'ETSI' ) {
      contentType = 'text/plain';
    }

    const header: HttpHeaders = new HttpHeaders({
      'Content-Type': contentType,
      ...this.HttpRequestHeaders(this.authenticationService.idToken)
    });

    return this.httpClient.post<JSON>(url, body, {headers: header});

  }

  private convertUseCaseToCITSUseCase(city: string, useCase: string): string {

    if (useCase != null) {
      switch (useCase.toUpperCase()) {
        case 'SMART-ROUTING': return 'IVI-DTT';
        case 'IVS-EVFT': return 'VMS';
        default: break;
      }
    }

    return useCase;
  }


}
