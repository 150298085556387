import {LaneConnections} from './lane-connections.model';

export class Dictionary {
  items = {};

  constructor() {
    this.items = {};
  }

  public has(key: number): boolean {
    return key in this.items;
  }

  public set(key: number, value: LaneConnections): void {
    this.items[key] = value;
  }

  public get(key: number): LaneConnections {
    return this.items[key];
  }

  public delete(key: number): boolean {
    if ( this.has(key) ) {
      delete this.items[key];
      return true;
    }
    return false;
  }

  public clear(): void {
    this.items = {};
  }
}

