
import {from, Observable, of, throwError} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthenticationService} from '../service/authentication.service';

@Injectable()
export class HttpCustomInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authenticationService: AuthenticationService, private ngZone: NgZone) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((returnedError) => {

          if (returnedError instanceof HttpErrorResponse) {

            switch (returnedError.status) {
              case 400: case 401:

                if ( this.checkResponseUrl( returnedError ) ) {
                  break;
                }

                console.log('Unauthorized...');
                // In order to execute 'logout' within the Angular context
                this.ngZone.run(() => {
                  this.authenticationService.logout().subscribe(res => this.router.navigate([ 'login' ]) );
                });
                break;

              case 404:

                if ( this.checkResponseUrl( returnedError ) ) {
                  break;
                }

                console.error('Unauthorized...');
                this.ngZone.run(() => {
                  this.router.navigate(['dashboard']);
                });
                break;

              default: break;
            }
          }

          return throwError(returnedError);
        }
      )
    );
  }

  private checkResponseUrl( returnedError: HttpErrorResponse): boolean {

    if ( returnedError.url !== null ) {
      if ( returnedError.url.length > 5) {
        if (returnedError.url.substr( returnedError.url.length - 5) === '/test') {
          return true;
        }
      }
    }

    return false;

  }
}
