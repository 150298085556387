export enum TimeFilter{

  NOW = Date.now(),

  // Note: month = 0 si Jan)
  TODAY = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()).getTime(),
  LAST_WEEK = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() - 7).getTime(),
  LAST_MONTH = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() - 1, new Date().getUTCDate()).getTime(),
  LAST_YEAR = new Date(new Date().getUTCFullYear() - 1, new Date().getUTCMonth(), new Date().getUTCDate()).getTime(),
  ALL = new Date(2004, 0, 0).getTime(),

}
