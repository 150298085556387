
<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>
  <ol>
    <li *ngFor="let row of data.content" >{{ row }}</li>
  </ol>
</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close style="border: 1px solid gray">Close</button>
</mat-dialog-actions>
