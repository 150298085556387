import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../service/authentication.service';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: [ './logout.component.css' ]
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router, private activeRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private appComponent: AppComponent) { }

  ngOnInit(): void {

    console.log('Logout in progress...');

    this.authenticationService.logout().subscribe(res => {

        if (res) {

          this.router.navigate([ 'dashboard' ]);
          this.appComponent.setIsLoggedIn(false);

        }
      },
      error => {
        console.log('Error during logout');
      });

  }
}
