import {Component, EventEmitter, Injectable, Input, OnInit, Output} from '@angular/core';
import {TimeFilter} from '../model/time.filter.model';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-time-filter',
  templateUrl: './time-filter.component.html',
  styleUrls: ['./time-filter.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class TimeFilterComponent implements OnInit {

  @Input() index: number;
  @Input() title: string;
  @Input() values: TimeFilter[];
  @Input() isLoading: boolean;
  @Output() filterChanged = new EventEmitter<TimeFilter>();
  @Output() refreshRequest = new EventEmitter<TimeFilter>();

  selected: TimeFilter;
  btnRefreshIsDisabled = false;
  interval: Subscription;

  constructor() { }

  ngOnInit(): void {
    this.selected = this.values[this.index];
  }

  getName(value: TimeFilter): string{
    return TimeFilter[value].replace('_', ' ');
  }

  selectValue(index: number): void{
    this.index = index;
    this.selected = this.values[index];
    this.filterChanged.emit(this.selected);
  }

  refreshData(): void {
    if ( !this.btnRefreshIsDisabled ) {

      this.refreshRequest.emit();
      this.btnRefreshIsDisabled = true;

      // enable btn click after 20 secs
      this.interval = interval(20000).subscribe(val => {
        this.btnRefreshIsDisabled = false;
        this.interval.unsubscribe();
      });
    }
  }

}
