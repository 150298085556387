
<app-time-filter [values]="getTimeFilterValues()" [title]="title" [index]="timeFilterIndex" [isLoading]="false"
                 (filterChanged)="filterChanged($event)" (refreshRequest)="updateUseCaseMessages()">
</app-time-filter>

<div class="alert alert-info horizontallyCenterContainerAndContent" role="alert" [style.display]="useCaseMessagesIsLoading ? 'block' : 'none'">
  <b>Loading data from the server...</b>
</div>

<div class="alert alert-warning horizontallyCenterContainerAndContent" role="alert" [style.display]="hiddenAlertWarning ? 'none': 'block'">
  <b>No data available for the specified period.</b>
</div>

<div class="alert alert-danger horizontallyCenterContainerAndContent" role="alert" [style.display]="hiddenAlertDanger ? 'none': 'block'">
  <b>An unexpected error occurred while downloading data from the server.</b>
</div>

<div class="horizontallyCenterContainerAndContent" role="alert" >
  <mat-form-field class="form-input">
    <mat-label>Filter table by Message Identifier</mat-label>
    <!-- Note: $event.traget might be null, this is why value is in red -->
    <input matInput placeholder="Messsage Identifier" type="text" (input)="filterTableChanged($event.target.value, true)" #filterTableInputField>
    <mat-icon matSuffix (click)=" resetTableFilter() ">close</mat-icon>
  </mat-form-field>
  <button mat-button (click)="navigateToTestingPage()" style="float: right; padding-top:0.25em; padding-bottom: 0.25em; min-width: fit-content !important">
    <img src="assets/img/code-slash.svg" width="22" height="22" [matTooltip]="'Parse input for ' + useCase" alt=""/> Test</button>
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 horizontalAlignment">

  <ng-container matColumnDef="msgId">
    <th mat-header-cell *matHeaderCellDef>Message identifier</th>
    <td class="nontransformed-cell" mat-cell *matCellDef="let element"> {{element.messageIdentifier}} </td>
  </ng-container>

  <ng-container matColumnDef="genTime">
    <th mat-header-cell *matHeaderCellDef>Generation time</th>
    <td mat-cell *matCellDef="let element"> {{element.generationTime.toLocaleString()}} </td>
  </ng-container>

  <ng-container matColumnDef="source">
    <th mat-header-cell *matHeaderCellDef>Source</th>
    <td mat-cell *matCellDef="let element"> {{element.sourceAuthority}} </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status of delivery</th>
    <td mat-cell *matCellDef="let element">
      <mat-chip-list>
        <mat-chip disabled [class]="'chip-'+  (element.statusOfDelivery.IP ? 'success' : 'warn')">IP</mat-chip>
        <mat-chip disabled [class]="'chip-'+ (element.statusOfDelivery.G5 ? 'success' : 'warn')">G5</mat-chip>
      </mat-chip-list>

    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let element">
      <img (click)="updateDisplayMessage(element)" class="clickable-cell" [src]=" setTableSelectedMessageToDisplayStyle(element) " height="21" width="21" alt="">
      <img (click)="mapClick(element)" class="clickable-cell" [src]=" setTableSelectedMessagePinStyle(element) " height="21" width="21"
           [hidden]="element.display === null || element.display.referencePoint === null" alt="">

      <img (click)="updateDisplayMessageAndClearMap(element)" class="clickable-cell" src="assets/img/exclamation-triangle.svg" height="21" width="21"
           [hidden]="element.display !== null && element.display.referencePoint !== null" alt="">
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['msgId', 'genTime', 'source', 'status', 'actions']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['msgId', 'genTime', 'source', 'status', 'actions'];"></tr>

</table>
<mat-paginator [pageSize]="10"
               showFirstLastButtons
               aria-label="Select page of periodic elements">
</mat-paginator>

<div class="message-content" *ngIf="messageToDisplay" [@fade]>
  <div style="display: inline-flex; width: 100%">
    <div style="display: inline; width: 90%;"><h4>Content of message with identifier {{messageToDisplay.messageIdentifier}}</h4></div>
    <div style="display: inline; width: 10%;">
      <a mat-button class="close" (click)="messageToDisplay = null">Close <img src="assets/img/cancel-white.svg" style="width: 15pt; height: 15pt" alt=""/></a>
    </div>
  </div>

  <div *ngFor="let element of messageContent" class="messageContent" >
    <pre *ngIf="element.visible">{{ element.content.length > 0 ? element.content : "empty" }}</pre>
  </div>
</div>

<div class="horizontallyCenterContainerAndContent">

  <!-- Map "filters error message" and "selected message not present anymore" -->
  <div *ngIf="this.mapShownMsg === null && this.showFiltersWarning"  class="alert alert-warning horizontallyCenterContainerAndContent" role="alert"
       [style.display]="'block'">
    <b>{{ this.warningMessage }}</b>
  </div>

  <!-- Map filters -->
  <button [disabled]="polylineType.disabled" [disableRipple]="true" (click)=" togglePolylines( polylineType ) "
          mat-button
          [class]=" setLegendButtonStyle( polylineType ) "
          [matTooltip]="polylineType.disabled ? 'Filter not available' : ('Use filter to toggle '+ polylineType.name)"
          *ngFor="let polylineType of polylineTypeLegends; let i = index">
    {{ polylineType.name }}
  </button>

  <!-- SPATEM help button: tooltip on how to interact with the map -->
  <button mat-button *ngIf="useCase === 'SPATEM' || useCase === 'MAPEM' || useCase === 'SI-TLP' || useCase === 'PI-ONSTREET'" (click)="openDialog(useCase)"
          style="background-color: transparent !important; min-width: 10pt !important;" >
    <mat-icon matSuffix style="color: black">info</mat-icon>
  </button>

  <!-- POI additional information -->
  <div *ngIf="this.legendSelectedType !== null && this.filteredParkingSpaceCounter >= 0">
    <h4>Parking spaces matching criteria: {{ this.filteredParkingSpaceCounter }}</h4>
  </div>

  <!-- Selected message information -->
  <div *ngIf="this.mapShownMsg !== null">
    <h3>Selected message: {{ this.mapShownMsg.messageIdentifier }}</h3>
  </div>

</div>

<!-- EVENT MAP -->
<app-event-map [mapTarget]="useCase + authorityShortName"
               (selectedMarkerChangedEvent)="selectedMarkerChanged($event)"
               [useCase]="useCase"
               (populateMapEvent)="populateMapEvent()" class="preventMapDoubleClickSelection">
</app-event-map>




