<div class="map" id="event-map{{mapTarget}}">

</div>

<div id="popup" class="ol-popup" #popup>
  <div id="popupContent" #popupContent>

  </div>
</div>

