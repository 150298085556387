import {UseCaseDataModel} from './use-case.data.model';

export class AuthorityInfoModel {

  authorityFullName: string;
  useCases: UseCaseDataModel[];

  constructor() {
    this.authorityFullName = '';
    this.useCases = [];
  }

}
