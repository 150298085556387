import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef, EventEmitter,
  Input,
  OnDestroy, Output,
  ViewChild
} from '@angular/core';
import TileLayer from 'ol/layer/Tile';
import {OSM} from 'ol/source';
import {Feature, Overlay, View} from 'ol';
import {defaults} from 'ol/interaction';
import { default as OlMap } from 'ol/Map';
import * as olProj from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Circle, LineString, Point, Polygon} from 'ol/geom';
import {decodePolyline} from '../util';
import {Fill, Icon, Stroke, Style, Text } from 'ol/style';
import {fromLonLat, Projection} from 'ol/proj';
import {isEmpty} from 'ol/obj';
import {MessageModel} from '../model/message.model';
import Timeout = NodeJS.Timeout;
import {Polyline } from '../model/polyline.model';
import {LaneConnections} from '../model/lane-connections.model';
import {Coordinate} from '../model/coordinate.model';
import {PolylineSpatemModel} from '../model/polyline-spatem.model';
import {SupportTrafficLightModel} from '../model/support-traffic-light.model';
import {Dictionary} from '../model/dictionary.model';
import {PopupModel} from '../model/popup.model';
import {PopupTrafficLightModel} from '../model/popup.traffic-light.model';
import {SSEMExtraData} from '../model/ssem-extradata.model';
import { PolylineSsemModel } from '../model/polyline-ssem.model';
import {PoizoneModel} from '../model/poizone.model';
import {MapData} from "../model/map.data.model";

@Component({
  selector: 'app-event-map',
  templateUrl: './event-map.component.html',
  styleUrls: ['./event-map.component.css']
})
export class EventMapComponent implements AfterViewInit, DoCheck, OnDestroy {

  map: OlMap;
  private vectorLayer;
  private vectorSource;
  private vectorSourceBaseItems: Feature<any>[];
  private eventsBaseItems: MessageModel[];
  private zonesBaseItems: Map<string, PoizoneModel>;

  private overlay: Overlay;

  private popupDetails: PopupModel;
  private popupTrafficLightDetails: PopupTrafficLightModel;

  private defaultCurrentZoomEvent: number; // default value when an event is selected from the table
  private currentZoom: number;
  private currentMapCenter: number[];

  private mapViewInitialZoom = 8;
  private laneSelectedZoom = 15;
  private interval: Timeout;

  // Properties used for setting the event's radius according to the message type (see function: 'setSelectedRadius')
  private selectedRadius: number;
  private radiusDENMIVIM = 5000; // using Units = 'm', this means 5000 meters = 5 km
  private radiusSPATEMMAPEM = 1000; // using Units = 'm', this means 1000 meters = 1 km
  private radiusPOI = 0; // using Units = 'm'
  private radiusHLNEPVA = 1000;

  isMapInLocationOnlyMode: boolean; // true until the user select a specific message
  isMapInLocationOnlyModePopupOpen: boolean; // true if isMapInLocationOnlyMode = false and popup open for an event; false otherwise
  mapLocationOnlyPopupMessageIdentifier: string; // null if no popups are open; otherwise message id of the one selected

  events: MessageModel[];

  public zones: Map<string, PoizoneModel>;

  intersectionTrafficLightDetails: SupportTrafficLightModel[];
  private laneConnectionsDict: Dictionary;

  private selectedLaneId: number;
  private currentPolylineWidth: number;
  private defaultPolylineWidth: number;
  private polylineEncoded: string;
  private polylineActiveFilter: string;

  private ssemPriorityLaneColor = '#4287f5';


  @Input() initialCenterLocation: number[] = [11.012606271136766, 45.866935665690896]; // default value
  @Input() mapTarget: string;
  @Input() useCase: string;
  @ViewChild('popup') popup: ElementRef;
  @ViewChild('popupContent') popupContent: ElementRef;

  @Output() selectedMarkerChangedEvent = new EventEmitter();
  @Output() populateMapEvent = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef,
              public elem: ElementRef)
  {
    this.events = [];
    this.eventsBaseItems = [];
    this.zones = new Map<string, PoizoneModel>();
    this.zonesBaseItems = new Map<string, PoizoneModel>();
    this.laneConnectionsDict = new Dictionary();
    this.intersectionTrafficLightDetails = [];
    this.vectorSourceBaseItems = [];
    this.selectedLaneId = -1;
    this.defaultCurrentZoomEvent = this.laneSelectedZoom;
    this.defaultPolylineWidth = 13;
    this.currentPolylineWidth = this.defaultPolylineWidth;
    this.polylineEncoded = '';
    this.polylineActiveFilter = null;
    this.popupDetails = null;
    this.popupTrafficLightDetails = null;

  }

  ngAfterViewInit(): void {

    this.isMapInLocationOnlyMode = true;

    this.overlay = new Overlay({
      element: this.popup.nativeElement,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    this.map = new OlMap({

      target: document.getElementById('event-map' + this.mapTarget),
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      view: new View({
        center: olProj.fromLonLat(this.initialCenterLocation),
        zoom: this.mapViewInitialZoom
      }),
      controls: [],
      overlays: [this.overlay],
      interactions: defaults({doubleClickZoom: false}) // remove zoom in when the user double clicks the map
    });

    this.vectorSource = new VectorSource({
      features: []
    });
    this.vectorSourceBaseItems = [];
    this.eventsBaseItems = [];
    this.zonesBaseItems = new Map<string, PoizoneModel>();

    this.vectorLayer = new VectorLayer({
      source: this.vectorSource,
    });

    this.map.addLayer(this.vectorLayer);

    this.populateMap();
    this.bindActionsToMap();

    this.currentZoom = this.map.getView().getZoom();
    this.currentMapCenter = this.map.getView().getCenter();
    this.isMapInLocationOnlyModePopupOpen = false;
    this.mapLocationOnlyPopupMessageIdentifier = null;

    // Update map size (especially when the sidebar get closed)
    this.interval = setInterval(() => {
      this.map.updateSize();
    }, 500);
  }

  bindActionsToMap(): void {

    this.map.on('singleclick', (event) => {

      this.isMapInLocationOnlyModePopupOpen = false;
      this.closePopup();

      this.map.forEachFeatureAtPixel(event.pixel, f => {

        if (f.getProperties().type === 'location') {
          const evt = this.getEventFromFeatureLocation(f);

          if (evt) { // manage popup content...

            this.popupDetails = new PopupModel(evt, f.getProperties().type);
            this.popupTrafficLightDetails = null;
            this.populatePopupLocation(evt);

            // show popup on the original coordinates:
            this.overlay.setPosition(olProj.fromLonLat([evt.display.referencePoint.longitude, evt.display.referencePoint.latitude]));
            this.isMapInLocationOnlyModePopupOpen = true;
            this.mapLocationOnlyPopupMessageIdentifier = evt.messageIdentifier;

            this.selectedMarkerChangedEvent.emit(evt);
          }
        }
        else if (f.getProperties().type === 'lane') {
          const [evt, polyline] = this.getEventFromFeaturePolyline(f);

          if (evt) { // manage popup content...

            if (polyline.spatemExtra != null) {
              this.popupDetails = new PopupModel(evt, f.getProperties().type);
              this.popupDetails.polyline = polyline;
              this.popupTrafficLightDetails = null;

              this.populatePopupLane( evt, polyline );

              // show popup with lanes information
              this.overlay.setPosition(olProj.fromLonLat([
                polyline.spatemExtra.laneInfo.popupNodeCoordinate.longitude,
                polyline.spatemExtra.laneInfo.popupNodeCoordinate.latitude
              ]));
              this.isMapInLocationOnlyModePopupOpen = true;
              this.mapLocationOnlyPopupMessageIdentifier = evt.messageIdentifier;
            }
            else if (polyline.ssemExtra != null) {
              // TODO: prioritized lane clicked -> implement popup
              // this.popupDetails = new PopupModel(evt, f.getProperties().type);
              // this.popupDetails.polyline = polyline;
              // this.popupTrafficLightDetails = null;
              //
              // this.populatePopupLane( evt, polyline, ssemStatus );
              //
              // // show popup with lanes information
              // this.overlay.setPosition(olProj.fromLonLat([
              //   polyline.spatemExtra.laneInfo.popupNodeCoordinate.longitude,
              //   polyline.spatemExtra.laneInfo.popupNodeCoordinate.latitude
              // ]));
              // this.isMapInLocationOnlyModePopupOpen = true;
              // this.mapLocationOnlyPopupMessageIdentifier = evt.messageIdentifier;
            }

          }

        }
        else if (f.getProperties().type === 'traffic-light') {
          const supportTrafficLight = this.getEventFromFeatureTrafficLight(f);

          if (supportTrafficLight) { // manage popup content...

            this.popupTrafficLightDetails = new PopupTrafficLightModel( supportTrafficLight );
            this.popupDetails = null;

            this.populatePopupTrafficLight( supportTrafficLight );

            // show popup on the original coordinates:
            this.overlay.setPosition(olProj.fromLonLat(supportTrafficLight.coordinates));
            this.isMapInLocationOnlyModePopupOpen = true;
            this.mapLocationOnlyPopupMessageIdentifier = supportTrafficLight.id;

          }
        }
        // else if (f.getProperties().type === 'trace') { // TODO: to be used with multiple traces
        //
        //   const [ traceLength, encoded, totalTraces, referencePoint ] = this.getEventFromFeatureTrace(f);
        //
        //   if ( traceLength ) {
        //      this.popupDetails = new PopupModel( evt, f.getProperties().type );
        //      this.popupDetails.polylineEncoded = encoded;
        //      this.popupDetails.totalTraces = totalTraces;
        //      this.popupDetails.traceLength = traceLength;
        //      this.popupTrafficLightDetails = null;
        //
        //     this.populatePopupTrace(totalTraces, encoded, traceLength);
        //
        //     // show popup on the original coordinates:
        //     this.overlay.setPosition(olProj.fromLonLat( referencePoint ));
        //     this.isMapInLocationOnlyModePopupOpen = true;
        //     this.mapLocationOnlyPopupMessageIdentifier = 'TODO set id'; // TODO
        //
        //   }
        //
        // }

      });
    });

    this.map.on('dblclick', (event) => {

      this.isMapInLocationOnlyModePopupOpen = false;
      this.closePopup();

      this.map.forEachFeatureAtPixel(event.pixel, f => {
        if (f.getProperties().type === 'lane') {

          const [evt, polyline] = this.getEventFromFeaturePolyline(f);

          if (evt) {

            if (polyline.spatemExtra != null) {

              // Manage lanes connections ------------------
              this.laneSelectedZoom = this.currentZoom;
              this.vectorSource.clear();
              this.selectedLaneId = polyline.spatemExtra.laneInfo.laneID;
              this.polylineEncoded = polyline.encoded;

              this.populateMap();
              // ------------------------------------------

            }
          }
        }
      });
    });

    this.map.on('moveend', (event) => {

      this.currentMapCenter = this.map.getView().getCenter();

      // Remove popup when the user zooms in or out
      const newZoom = this.map.getView().getZoom();
      if (this.currentZoom !== newZoom) {
        this.currentZoom = newZoom;
      }

      // Problems: when timefilter changes, variables are not correctly reset
      // if (this.isMapInLocationOnlyMode === false) { // increase polyline width
      //   this.clearMap();
      //
      //   // Add default features to the map (es. relevant area)
      //   this.vectorSourceBaseItems.forEach(f => {
      //     this.vectorSource.addFeature( f );
      //   });
      //
      //   // TODO: Increase/Decrease polyline width proportionally to zoom level -----------------
      //   if (this.currentZoom > 18) {
      //     if (this.currentZoom === this.defaultCurrentZoomEvent) {
      //       this.currentPolylineWidth = this.defaultPolylineWidth; // 1:1 relationship between zoom and width
      //     }
      //     else {
      //       this.currentPolylineWidth = this.defaultPolylineWidth + ((this.currentZoom / this.defaultPolylineWidth) * 3);
      //       console.log('updated width: ' + this.currentPolylineWidth);
      //     }
      //   }
      //   // ---------------------------------------------------------------------------------------------------------
      //
      //   for (const e of this.events) {
      //     this.createPolylines(e, this.currentMapCenter, this.currentPolylineWidth, this.polylineActiveFilter);
      //   }
      // }

    });
  }

  populateMap(): void {

    let intersectionCenter: number[] = null;
    this.laneConnectionsDict.clear();
    this.vectorSourceBaseItems = [];
    this.eventsBaseItems = [];
    this.zonesBaseItems = new Map<string, PoizoneModel>();
    this.vectorSource.clear();

    // clear map's filters
    this.populateMapEvent.emit();

    for (const event of this.events) {

      intersectionCenter = [event.display.referencePoint.longitude, event.display.referencePoint.latitude];

      this.map.setView(new View({
        center: olProj.fromLonLat(intersectionCenter),
        zoom: this.laneSelectedZoom, // Use 'this.laneSelectedZoom' to keep the current zoom
        projection: new Projection({
          code: 'EPSG:3857',
          units: 'm', // set METERS as unit
          metersPerUnit: 1,
        })
      }));

      // Insert icon on the map
      const eventLocationMarker = this.createLocationMarker(event);

      // Draw circle around the event location
      const eventCircle = this.createRelevanceAreaCircle(event);

      /* Add both the event point and the circle to the map
      * (note: different features must have different IDs, or only the first added feature is going to be displayed) */
      this.vectorSource.addFeatures([eventLocationMarker, eventCircle]);
      this.vectorSourceBaseItems.push(eventLocationMarker, eventCircle);
      this.eventsBaseItems.push(event);
      this.zonesBaseItems = this.zones;

      // Manage polylines (SPATEM and SSEM)
      this.createLanesConnectionMap(event);

      // Display polylines on map
      this.createPolylines(event, intersectionCenter, this.currentPolylineWidth, null);

    }

    // If available, display the zone with the specified id
    this.createPolygons();

  }

  populateMapLocationsOnly(): void {

    this.polylineEncoded = '';
    this.selectedLaneId = -1;
    this.vectorSource.clear(); // remove points and polylines

    this.isMapInLocationOnlyMode = true;

    // clear map's filters
    this.populateMapEvent.emit();

    for (const event of this.events) {

      if (event.display != null) {

        if (event.display.referencePoint != null) {
          this.map.setView(new View({
            center: this.currentMapCenter, // do not update the visualized map center
            zoom: this.mapViewInitialZoom
          }));

          const eventFeature = this.createLocationMarker(event);
          this.vectorSource.addFeature(eventFeature);

          // Update the popup content of the selected event
          this.updatePopupContent(event);
        }
      }

    }

    // check if the message with the open popup has been removed
    const filtered = this.events.filter(evt => evt.messageIdentifier === this.mapLocationOnlyPopupMessageIdentifier);
    if (filtered.length === 0) {
      this.overlay.setPosition(undefined); // close popup
    }

    // If present, display all the zones
    this.createPolygons();

  }

  filterMap(polylineType: string, disableFilter: boolean): number {

    let cntEvents = -1;
    if (this.isMapInLocationOnlyMode === false) {

      if (polylineType === 'spatemmapem' || polylineType === 'ingresslane'
        || polylineType === 'egresslane' || polylineType === 'prioritizedlane')
      {
        this.clearMap();

        // Restore original features
        this.vectorSourceBaseItems.forEach(f => {
          this.vectorSource.addFeature(f);
        });

        // Check if we are applying the filter or if we are disabling it
        polylineType = (!disableFilter) ? polylineType : null;

        // Create Polylines according to the "polylineType". If "null", restore the original data
        for (const event of this.events) {
          this.createPolylines(event, null, this.currentPolylineWidth, polylineType);
        }

      }
      else if (polylineType === 'AVAILABLE' || polylineType === 'OCCUPIED' || polylineType === 'RESERVED' || polylineType === 'UNKNOWN'
        || polylineType === 'DISABLED_PARKING_SPACE' || polylineType === 'LOAD_AND_OFFLOAD_GOODS'
        || polylineType === 'OTHER')
      {
        this.clearMap();

        // Restore original features
        this.vectorSourceBaseItems.forEach(f => {
          this.vectorSource.addFeature(f);
        });

        // Restore original zones
        this.zones = this.zonesBaseItems;

        if (disableFilter) { // Restore original events
          this.events = this.eventsBaseItems;
        }
        else {
          /* > True if we need to filter based on Parking Status;
          * > False if we need to filter by Parking Type */
          const filterByAvailability = (polylineType === 'AVAILABLE' || polylineType === 'OCCUPIED'
            || polylineType === 'RESERVED' || polylineType === 'UNKNOWN');

          // Filter events according to POI Sensor Status
          this.events = [];
          for (const event of this.eventsBaseItems) {

            if (this.createPOILocations(event, polylineType, filterByAvailability)) {
              this.events.push(event);
            }
          }
        }

        // Update map
        this.updateMapAndFilters(false);

        cntEvents = this.events.length;
      }
    }

    return cntEvents;
  }

  /* ******** Extract information from the pixel clicked on the map ******** */
  private getEventFromFeatureLocation(feature): MessageModel {

    const plausibles = this.vectorSource.getFeatures().filter(f => {
      return f.getId() === feature.getId();
    });

    if (plausibles.length > 0) {
      return this.events.filter(evt => {
        return ('location_' + evt.id) === plausibles[0].getId();
      })[0];
    } else {
      return null;
    }

  }

  private getEventFromFeaturePolyline(feature): [MessageModel, Polyline] {

    const plausibles = this.vectorSource.getFeatures().filter(f => f.getId() === feature.getId());
    let retValMessageModel = null;
    let retValPolyline = null;

    if (plausibles.length > 0) {

      this.events.forEach(evt => {

        if (evt.display.polylines !== null) {

          evt.display.polylines.forEach(polyline => {

            if (polyline.spatemExtra !== null) {
              if (polyline.spatemExtra.laneInfo !== null) {

                if (('polyline_' + polyline.spatemExtra.laneInfo.id) === plausibles[0].getId()) {
                  retValMessageModel = evt;
                  retValPolyline = polyline;
                }
              }
            }

          });
        }

      });

      return [retValMessageModel, retValPolyline];
    }

    return null;

  }

  private getEventFromFeatureTrafficLight(feature): SupportTrafficLightModel {

    const plausibles = this.vectorSource.getFeatures().filter(f => f.getId() === feature.getId());
    let retValue: SupportTrafficLightModel = null;

    if (plausibles.length > 0) {

      this.intersectionTrafficLightDetails.forEach(item => {

        if (item !== null) {
          if (item.id === plausibles[0].getId()) {
            retValue = item;
          }
        }
      });

      return retValue;
    }
    return null;

  }

  // TODO: replace returned type with a class
  private getEventFromFeatureTrace(feature): [string, string, number, number[]] {

    const plausibles = this.vectorSource.getFeatures().filter(f => f.getId() === feature.getId());
    let retEncoded: string = null;
    let retLength: string = null;
    let retTotalTraces: number = null;
    let retCoordinate: number[] = null;

    if (plausibles.length > 0) {

      this.events.forEach(evt => {

        if (evt.display.polylines !== null) {

          evt.display.polylines.forEach(polyline => {

            if (('polyline_' + polyline.encoded) === plausibles[0].getId() && retEncoded === null) {
              retEncoded = polyline.encoded;
              retLength = polyline.length.toString();
              retTotalTraces = evt.display.polylines.length;
              retCoordinate = [evt.display.referencePoint.longitude, evt.display.referencePoint.latitude];
            }

          });

        }
      });

    }

    return [retLength, retEncoded, retTotalTraces, retCoordinate];

  }
  /* *********************************************************************** */

  setSelectedRadius(messageType: string): void {

    switch (messageType.toUpperCase()) {
      case 'DENM':
      case 'IVIM':
        this.selectedRadius = this.radiusDENMIVIM;
        break;
      case 'SPATEM':
      case 'MAPEM':
      case 'SSEM':
        this.selectedRadius = this.radiusSPATEMMAPEM;
        break;
      case 'POI':
        this.selectedRadius = this.radiusPOI;
        break;
      case 'EPVA':
        this.selectedRadius = this.radiusHLNEPVA;
        break;
      default:
        this.selectedRadius = this.radiusSPATEMMAPEM;
        break;
    }
  }

  /* ********** Update Map and its content ********** */
  updateMap(): void {
    this.updateMapAndFilters(true);
  }

  updateMapAndFilters(clearMapFilters: boolean): void {
    // Function called only when the map is displaying a single event

    let intersectionCenter: number[] = null;
    this.isMapInLocationOnlyMode = false;
    this.laneConnectionsDict.clear();
    this.vectorSourceBaseItems = [];
    this.vectorSource.clear();

    // clear map's filters
    if (clearMapFilters) {
      this.populateMapEvent.emit();
    }

    for (const msg of this.events) {

      // Update intersection center (used to center the map)
      intersectionCenter = [msg.display.referencePoint.longitude, msg.display.referencePoint.latitude];

      this.map.setView(new View({
        // when the map updates, do not change the center not the current zoom, leave what set by the user
        center: this.map.getView().getCenter(),
        zoom: this.currentZoom,
        projection: new Projection({
          code: 'EPSG:3857',
          units: 'm', // set METERS as unit
          metersPerUnit: 1,
        })
      }));

      // Insert icon on the map
      const eventLocationMarker = this.createLocationMarker(msg);

      // Draw circle around the event location
      const eventCircle = this.createRelevanceAreaCircle(msg);

      /* Add both the event point and the circle to the map
      * (note: different features must have different IDs, or only the first added feature is going to be displayed) */
      this.vectorSource.addFeatures([eventLocationMarker, eventCircle]);
      this.vectorSourceBaseItems.push(eventLocationMarker, eventCircle);

      // Manage polylines
      this.createLanesConnectionMap(msg);

      // Display polylines on map
      this.createPolylines(msg, intersectionCenter, this.currentPolylineWidth, null);

      // Update popup content
      this.updatePopupContent(msg);
    }

    // For usecase POI, display zones on the map
    this.createPolygons();

  }

  updatePopupContent(messageModel: MessageModel): void {

    if (this.popupDetails !== null) { // Then the popup is open on the map

      if ( this.popupDetails.details.id === messageModel.id) {

        switch (this.popupDetails.type) {
          case 'location':
            this.populatePopupLocation(messageModel);
            break;
          case 'lane':
            this.populatePopupLane(messageModel, this.popupDetails.polyline);
            break;
          case 'polyline': // TODO: not tested
            this.populatePopupTrace(this.popupDetails.totalTraces, this.popupDetails.polylineEncoded);
            break;
          default:
            this.closePopup();
            break;
        }
      }
    }

  }

  updateTrafficLightPopupContent(supportTrafficLight: SupportTrafficLightModel): void {

    if (this.popupTrafficLightDetails !== null // Then the popup is open on the map
      && supportTrafficLight !== null )
    {
      if ( this.popupTrafficLightDetails.details.id === supportTrafficLight.id )
      {
        this.populatePopupTrafficLight(supportTrafficLight);
      }
    }

  }

  updateZoomMap(): void {
    this.map.getView().setZoom( this.currentZoom );
  }
  /* ************************************************ */

  clearMap(): void {

    this.polylineEncoded = '';
    this.selectedLaneId = -1;
    this.vectorSource.clear(); // remove points and polylines

    this.zones = new Map<string, PoizoneModel>(); // remove stored zones

    // TODO:
    // close popup only (every time the user changes message, or every time the popup is not already open in LocationOnlyMode)
    // if (this.isMapInLocationOnlyMode === false || this.isMapInLocationOnlyModePopupOpen === false) {
    this.overlay.setPosition(undefined); // close popup
    this.popupDetails = null;
    this.mapLocationOnlyPopupMessageIdentifier = null;
  }

  closePopup(): void {
    this.overlay.setPosition(undefined); // close popup
    this.popupDetails = null;
    this.popupTrafficLightDetails = null;
    this.mapLocationOnlyPopupMessageIdentifier = null;

    if ( this.isMapInLocationOnlyMode ) {
      // No valid event has been clicked (while the map is showing locations only)
      // Note: do not call 'emit' when we are not in location only mode. Otherwise,
      // the selected row in the table would get deselected even if the displayed event did not change
      this.selectedMarkerChangedEvent.emit(null);
    }
  }

  // TODO: implemented to be used with lane dynamic width
  // clearMapAndReset(): void {
  //
  //   this.clearMap();
  //   this.isMapInLocationOnlyMode = true;
  //   this.isMapInLocationOnlyModePopupOpen = false;
  //
  //   this.currentZoom = this.defaultCurrentZoomEvent;
  //   this.laneSelectedZoom = this.defaultPolylineWidth;
  //   this.vectorSourceBaseItems = [];
  //
  // }

  // Center map and reset the zoom to the default value
  centerMapOnLocationAndResetZoom(location: number[]): void {

    this.map.setView(new View({
      center: olProj.fromLonLat(location),
      zoom: this.mapViewInitialZoom
    }));

  }

  // Center map and do not change zoom-level
  centerMapOnLocation(location: number[]): void {
    this.map.setView(new View({
      center: olProj.fromLonLat(location),
      zoom: this.currentZoom
    }));

  }

  /* ********** Create Graphical Elements ********** */
  private createLanesConnectionMap(event: MessageModel): void {

    if (event != null) {
      if (event.display != null) {
        if (event.display.polylines != null) {

          for (const polyline of event.display.polylines) {
            if (polyline.spatemExtra !== null) {
              if (polyline.spatemExtra.laneInfo !== null) {

                const laneId = polyline.spatemExtra.laneInfo.laneID;
                const firstNode = polyline.spatemExtra.laneInfo.firstNodeCoordinate;

                this.laneConnectionsDict.set(laneId,
                  new LaneConnections(laneId, polyline.spatemExtra.laneInfo.connectedTo, [firstNode.longitude, firstNode.latitude])
                );

              }
            }
          }

        }
      }
    }
  }

  private createLaneNumberFeature(polylineSpatemExtra: PolylineSpatemModel, polylineEncoded: string): Feature<Point> {

    if (polylineSpatemExtra != null) {
      if (polylineSpatemExtra.laneInfo.firstNodeCoordinate !== null) {

        const eventLaneNumber = new Feature({
          geometry: new Point(fromLonLat([
            polylineSpatemExtra.laneInfo.firstNodeCoordinate.longitude,
            polylineSpatemExtra.laneInfo.firstNodeCoordinate.latitude
          ]))
        });
        eventLaneNumber.setId('lane_number_' + polylineEncoded);

        // Display text on the map -----------------------------------------------
        const eventLaneNumberStyle = new Style({

          text: new Text({
            text: polylineSpatemExtra.laneInfo.laneID.toString(),
            scale: 1.5,
            fill: new Fill({
              color: 'white'
            }),
            stroke: new Stroke({
              color: 'black',
              width: 3
            })
          })

        });
        eventLaneNumber.setStyle(eventLaneNumberStyle);
        // -------------------------------------------------------------------

        return eventLaneNumber;
      }
    }

    return null;
  }

  private createLocationMarker(event: MessageModel): Feature<Point> {
    let img = '../../assets/img/geo-alt-fill.svg';
    let scaleImg = 2;

    const eventFeature = new Feature({
      type: 'location',
      geometry: new Point(olProj.fromLonLat([event.display.referencePoint.longitude, event.display.referencePoint.latitude])),
    });
    eventFeature.setId('location_' + event.id);

    // cambia pittogramma in base agli use case
    switch (this.useCase) {
      case 'HLN-APR':
        img = '../../assets/img/apr-marker.svg';
        scaleImg = 0.05;
        break;
      case 'HLN-AZ':
        img = '../../assets/img/az-marker.svg';
        scaleImg = 0.20;
        break;
      case 'IVS-EVFT':
        img = '../../assets/img/geo-alt-fill.svg';
        scaleImg = 2;
        break;
      case 'IVS-OSI':
        img = '../../assets/img/danger.svg';
        scaleImg = 0.05;
        break;
      case 'IVS-DSLI':
        img = '../../assets/img/ivs-dsli-marker.svg';
        scaleImg = 0.05;
        break;
      case 'HLN-OR':
        img = '../../assets/img/or-marker.png';
        scaleImg = 0.07;
        break;
      case 'RWW':
        img = '../../assets/img/rww-marker.svg';
        scaleImg = 0.05;
        break;
      case 'HLN-SV':
        img = '../../assets/img/danger.svg';
        scaleImg = 0.05;
        break;
      case 'HLN-TJA':
        img = '../../assets/img/tja-marker.svg';
        scaleImg = 0.21;
        break;
      case 'HLN-WCW':
        img = '../../assets/img/wcw-marker.svg';
        scaleImg = 0.50;
        break;
      case 'HLN-EPVA':
        img = '../../assets/img/danger.svg';
        scaleImg = 0.05;
        break;
      case 'PI-OFFSTREET': case 'PI-ONSTREET':
        img = '../../assets/img/poi-marker.svg';
        scaleImg = 0.05;
        break;
      case 'SI-TLP':
        img = '../../assets/img/si-tlp-marker.svg';
        scaleImg = 0.05;
        break;
      case 'HLN-VRU':
        img = '../../assets/img/vru-marker.svg';
        scaleImg = 0.05;
        break;
      case 'SPATEM':
        img = '../../assets/img/traffic-light.3.svg';
        scaleImg = 0.1;
        break;
      default:
        break;
    }

    // Insert icon on the map... ---------------------
    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.4, 0.8],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: img,
        scale: scaleImg
      })
    });
    // -----------------------------------------------

    eventFeature.setStyle(iconStyle);

    return eventFeature;
  }

  private createPOILocations(event: MessageModel, polylineFilter: string, filterByAvailability: boolean): boolean {

    // Update active filter for visualization purposes
    this.polylineActiveFilter = polylineFilter;

    if (event != null) {
      if (event.optionalParams != null) {

        let parameter = undefined;

        if ( filterByAvailability && event.optionalParams['status'] != null) { // use this to filter by Availability
          parameter = event.optionalParams['status'];

          if (filterByAvailability) {
            // Note: soluzione temporanea per gestire lo stato dei parcheggi "RESERVED_AND_FREE" and "RESERVED_AND_OCCUPIED"
            if (parameter === 'RESERVED_AND_FREE') {
              parameter = 'AVAILABLE';
            } else if (parameter === 'RESERVED_AND_OCCUPIED') {
              parameter = 'OCCUPIED';
            }
          }
        }
        else if (event.optionalParams['type'] != null) {
          parameter = event.optionalParams['type'];
        }

        if (parameter != null && parameter === polylineFilter) {
          return true;
        }
      }
    }

    return false;
  }

  private createPolygons(): void {

    // Create zones polygons (only for those zones containing at least one message)
    for ( const key in this.zones ) {
      const zone = this.zones[key];

      if (zone !== undefined) {
        const geom = new Polygon([zone.polygonVertexes.map(p => [p.longitude, p.latitude])]);
        geom.transform('EPSG:4326', 'EPSG:3857');

        const feature = new Feature({
          type: 'area',
          geometry: geom
        });
        feature.setStyle(new Style({
          stroke: new Stroke({
            width: 5,
            color: zone.color
          }),
          fill: new Fill({
            color: zone.color + '4C'
          }),
        }));

        feature.setId('zone_id_' + key);
        this.vectorSource.addFeature(feature);
      }
    }

  }

  private createPolylines(event: MessageModel, intersectionCenter: number[], polylineWidth: number, polylineFilter: string): void {
    let eventLaneTypePoint: Point = null;
    let polylineSpatemExtra: PolylineSpatemModel = null;
    // Update active filter for visualization purposes
    this.polylineActiveFilter = polylineFilter;
    this.intersectionTrafficLightDetails = [];

    if (event != null) {
      if (event.display != null) {
        if (event.display.polylines != null) {

          for (const polyline of event.display.polylines) {

            if (polylineFilter === null || polyline.type.toLowerCase() === polylineFilter.toLowerCase()) {

              const coordinates = decodePolyline(polyline.encoded, 6).map(latLon => {
                return [latLon[1], latLon[0]];
              });

              let polylineColor = '';
              let polylineType = 'route';

              switch (polyline.type.toLowerCase()) {
                case 'detectionarea':
                  polylineColor = 'green';
                  break;
                case 'relevancearea':
                  polylineColor = 'red';
                  break;
                case 'eventhistory':
                  polylineColor = 'blue';
                  break;
                case 'traces':
                  polylineColor = 'purple';
                  // eventTypePoint = new Point(fromLonLat(coordinates[0]));
                  polylineType = 'trace';
                  break;
                case 'spatemmapem':
                  polylineColor = 'gray';
                  polylineType = 'lane';
                  eventLaneTypePoint = new Point(fromLonLat(coordinates[0]));
                  break;
                case 'ingresslane':
                  polylineColor = 'darkkhaki';
                  polylineType = 'lane';
                  eventLaneTypePoint = new Point(fromLonLat(coordinates[0]));

                  polylineColor = this.computePolylineColorSSEM(polyline.ssemExtra, polyline.type.toLowerCase(), polylineColor);
                  break;
                case 'egresslane':
                  polylineColor = 'brown';
                  polylineType = 'lane';
                  eventLaneTypePoint = new Point(fromLonLat(coordinates[coordinates.length - 1]));

                  polylineColor = this.computePolylineColorSSEM(polyline.ssemExtra, polyline.type.toLowerCase(), polylineColor);
                  break;
                default:
                  polylineColor = 'black';
              }

              polylineSpatemExtra = polyline.spatemExtra;

              // manage lanes connections ------------------------------------------
              if (this.selectedLaneId !== -1) {

                const selectedLane = this.laneConnectionsDict.get(this.selectedLaneId);

                if (selectedLane !== undefined) {

                  selectedLane.connectedTo.forEach(connectedTo => {

                    const connectionLane = this.laneConnectionsDict.get(connectedTo);

                    if (connectionLane !== undefined) {

                      const connectionPoint = [
                        selectedLane.connectionPoint,
                        intersectionCenter,
                        connectionLane.connectionPoint
                      ];

                      const connectionLine = new LineString(connectionPoint);
                      connectionLine.transform('EPSG:4326', 'EPSG:3857');

                      const connectionFeature = new Feature({
                        type: 'lane',
                        geometry: connectionLine
                      });
                      connectionFeature.setStyle(new Style({
                        stroke: new Stroke({
                          width: 5,
                          color: 'black',
                          lineDash: [1, 10],
                          lineDashOffset: 6,
                          lineJoin: 'miter'
                        })
                      }));
                      connectionFeature.setId('connection_polyline_' + connectionLine + Math.random());
                      this.vectorSource.addFeature(connectionFeature);

                    }

                  });
                }
              }
              // ------------------------------------------------------------------------

              const polyString = new LineString(coordinates);
              polyString.transform('EPSG:4326', 'EPSG:3857');
              const f = new Feature({
                type: polylineType,
                geometry: polyString
              });

              /* Manage spatem-mapem ingress/egress lane
              * If the polyline is a ingress or a egress lane (SPATEM), draw a point indicating the direction of the lane */
              if (eventLaneTypePoint !== null) {

                // Display lane direction -------------------------------------------------
                const eventLaneType = new Feature({
                  geometry: eventLaneTypePoint
                });
                eventLaneType.setId('lane_' + polyline.encoded);
                this.vectorSource.addFeature(eventLaneType);
                // -----------------------------------------------------------------------

                // SPATEM/MAPEM + SSEM: Display lane number close to the center of the intersection
                const spatemLaneNumberFeature = this.createLaneNumberFeature(polylineSpatemExtra, polyline.encoded);

                if (spatemLaneNumberFeature !== null) {
                  this.vectorSource.addFeature(spatemLaneNumberFeature);
                }
              }

              // SPATEM + SSEM Traffic light status --------------------------------------------------------------------------------------
              const spatemTrafficLightFeature = this.createTrafficLightFeature(polylineSpatemExtra, polyline.encoded);

              if (spatemTrafficLightFeature !== null) {
                this.vectorSource.addFeature(spatemTrafficLightFeature);
              }
              // ------------------------------------------------------------------------------------------------------------------------

              // Set polyline style
              f.setStyle(new Style({
                stroke: new Stroke({
                  width: polylineWidth,
                  color: polylineColor,
                })
              }));

              f.setId('polyline_' + polyline.encoded);

              this.vectorSource.addFeature(f);
            }
          }

        }
      }
    }

  }

  private createRelevanceAreaCircle(event: MessageModel): Feature<Circle> {

    if (event.display.messageType === "POI") {
      return new Feature();
    }

    // Adjust the radius of the circle according to the map resolution
    const fixedRadius = this.selectedRadius / olProj.getPointResolution('EPSG:3857', 1,
      olProj.fromLonLat([event.display.referencePoint.longitude, event.display.referencePoint.latitude]));

    const eventCircle = new Feature({
      type: 'area',
      geometry: new Circle(olProj.fromLonLat([event.display.referencePoint.longitude,
        event.display.referencePoint.latitude]), fixedRadius),
    });
    eventCircle.setId('area_' + event.messageIdentifier);

    const circleStyle = new Style({
      fill: new Fill({
        color: '#C8C8FF4C'
      }),
      stroke: new Stroke({
        width: 2,
        color: 'black'
      })
    });
    eventCircle.setStyle(circleStyle);

    return eventCircle;
  }

  private createTrafficLightFeature(polylineSpatemExtra: PolylineSpatemModel, polylineEncoded: string): Feature<Circle> {

    if (polylineSpatemExtra != null) {
      if (polylineSpatemExtra.intersectionStates !== null) {

        if (polylineSpatemExtra.intersectionStates.length > 0) {

          const intersectionMoy = polylineSpatemExtra.intersectionMoy;
          const nowTimeAsMoy = polylineSpatemExtra.nowTimeAsMoy;
          let trafficLightColor = 'black';
          let likelyTime = 0;
          let currentStateTimeSpeed = -1;
          let eventState = 'undefined';

          // Note: a spatem might contain more than one intersection. However, we only manage cases with one.
          const intersectionStates = polylineSpatemExtra.intersectionStates[0];

          // Sequentially check the sorted list of stateTimeSpeed until we find the interval that contains the server "nowTime".
          // Such interval would define the current state of the traffic light.
          for (let i = 0; i < intersectionStates.stateTimeSpeed.length; i++) {

            eventState = intersectionStates.stateTimeSpeed[i].eventState;
            likelyTime += intersectionStates.stateTimeSpeed[i].likelyTime;

            if (likelyTime >= nowTimeAsMoy) {

              trafficLightColor = this.defineTrafficLightColor(eventState);
              currentStateTimeSpeed = i;
              break;
            }

          }

          const trafficLightCoordinates = this.defineTrafficLightCoordinates(polylineSpatemExtra.laneInfo.firstNodeCoordinate,
            polylineSpatemExtra.laneInfo.lastNodeCoordinate, polylineSpatemExtra.laneInfo.laneID.toString());

          const eventLaneTrafficLightStatus = new Feature({
            type: 'traffic-light',
            geometry: new Circle(olProj.fromLonLat(trafficLightCoordinates), 1)
          });
          eventLaneTrafficLightStatus.setId('lane_traffic_light_' + polylineEncoded);

          const eventLaneTrafficLightStatusStyle = new Style({
            fill: new Fill({
              color: trafficLightColor
            }),
            text: new Text({
              text: polylineSpatemExtra.laneInfo.laneID.toString(),
              scale: 1,
              fill: new Fill({
                color: 'white'
              }),
              stroke: new Stroke({
                color: 'black',
                width: 3
              })
            }),
            zIndex: 1 // In order to always display traffic lights in front of polylines
          });
          eventLaneTrafficLightStatus.setStyle(eventLaneTrafficLightStatusStyle);

          const trafficLightDetails = new SupportTrafficLightModel(
            'lane_traffic_light_' + polylineEncoded,
            eventState,
            trafficLightCoordinates,
            polylineSpatemExtra.intersectionStates[0],
            currentStateTimeSpeed,
            nowTimeAsMoy,
            intersectionMoy,
            likelyTime
          );
          this.intersectionTrafficLightDetails.push( trafficLightDetails );

          // Update popup content (if needed)
          this.updateTrafficLightPopupContent( trafficLightDetails );

          return eventLaneTrafficLightStatus;
        }
      }
    }

    return null;
  }

  private computePolylineColorSSEM(ssemExtra: PolylineSsemModel, laneType: string, color: string): string {

    if (ssemExtra == null) {
      return color;
    }

    if (ssemExtra.laneId == null || ssemExtra.laneId === -1) {
      return color;
    }
    else if (laneType === 'ingresslane') {
      return this.ssemPriorityLaneColor;
    }
    else if (laneType === 'egresslane') {
      return this.ssemPriorityLaneColor; // TODO: define and set "out bound lane color"
    }

    return color;

  }
  /* *********************************************** */

  private defineTrafficLightCoordinates(firstNode: Coordinate, lastNode: Coordinate, laneType: string): number[] {

    const tmpFirstNode = firstNode;
    const tmpLastNode = lastNode;

    const delta = 0.0001;

    if (tmpFirstNode.longitude + delta >= tmpLastNode.longitude
      && tmpFirstNode.longitude - delta <= tmpLastNode.longitude)
    { // direction toward vertical

      if (tmpFirstNode.latitude >= tmpLastNode.latitude) { // from bottom to top
        return [tmpFirstNode.longitude, tmpFirstNode.latitude + 0.000015];
      } else { // from top to bottom
        return [tmpFirstNode.longitude, tmpFirstNode.latitude - 0.000015];
      }
    } else {

      if (tmpFirstNode.longitude >= tmpLastNode.longitude) {

        if (tmpFirstNode.latitude + delta >= tmpLastNode.latitude
          && tmpFirstNode.latitude - delta <= tmpLastNode.latitude)
        { // direction toward horizontally right
          return [tmpFirstNode.longitude + 0.000015, tmpFirstNode.latitude];
        } else if (tmpFirstNode.latitude > tmpLastNode.latitude) { // direction toward top-right
          return [tmpFirstNode.longitude + 0.000015, tmpFirstNode.latitude];
        } else { // direction bottom-right
          return [tmpFirstNode.longitude, tmpFirstNode.latitude - 0.000015];
        }
      }
      else {

        if (tmpFirstNode.latitude + delta >= tmpLastNode.latitude
          && tmpFirstNode.latitude - delta <= tmpLastNode.latitude)
        { // direction toward horizontally left
          return [tmpFirstNode.longitude - 0.000015, tmpFirstNode.latitude];
        } else if (tmpFirstNode.latitude > tmpLastNode.latitude) { // direction toward top-left
          return [tmpFirstNode.longitude, tmpFirstNode.latitude + 0.000015];
        } else { // direction bottom-left
          return [tmpFirstNode.longitude - 0.000015, tmpFirstNode.latitude];
        }
      }

    }

  }

  private defineTrafficLightColor(state: string): string {

    switch (state.toLowerCase().trim()) {

      case 'unavailable':
      case 'dark': // General states
        return 'black';

      case 'stop-then-proceed':
      case 'stop-and-remain': // Stop states - represented in most cases by red lights
        return 'red';

      case 'pre-movement': // Pending green, prepare to proceed
        return 'red';

      case 'permissive-movement-allowed':
      case 'protected-movement-allowed': // States for go - "represented in most cases by green lights"
        return 'green';

      case 'permissive-clearance':
      case 'protected-clearance': // Intermediate states to stop
      case 'caution-conflicting-traffic': // States in “standby mode” or partially signalled intersections
        return 'orange';

      default:
        return 'black';

    }
  }

  // TODO: not used
  private convertSpatemMoyTommss(spatemMoy: number): string {
    // moy gets computed as: ( seconds + minutes * 60) * 10

    const inSeconds = spatemMoy / 10;
    const minutes = Math.floor(inSeconds / 60);
    const seconds = inSeconds % 60; // - (minutes * 60);

    return minutes + ':' + seconds;

  }

  ngDoCheck(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.map.setTarget(null);  // does not exist
    this.map = null;

    clearInterval(this.interval);
  }

  /* ********** Populate Popups ********** */
  private populatePopupLocation(evt: MessageModel): void {

    this.popupContent.nativeElement.innerHTML = '';
    if (evt.generationTime != null) {
      this.popupContent.nativeElement.innerHTML = evt.generationTime + '<br />';
    }

    this.popupContent.nativeElement.innerHTML += evt.display.messageType + '<br />';
    this.popupContent.nativeElement.innerHTML += 'latitude: ' + evt.display.referencePoint.latitude + '<br />';
    this.popupContent.nativeElement.innerHTML += 'longitude: ' + evt.display.referencePoint.longitude + '<br />';
    this.popupContent.nativeElement.innerHTML += '<a href="http://maps.google.com/?q=' + evt.display.referencePoint.latitude
      + ',' + evt.display.referencePoint.longitude + '" target="_blank">Open in maps</a>';

    if (evt.optionalParams != null) {

      this.popupContent.nativeElement.innerHTML += '<br />';

      for (const [key, value] of Object.entries(evt.optionalParams)) {

        if (this.isOptionalParamsToDisplay(key)) {
          this.popupContent.nativeElement.innerHTML += key + ': ' + this.manageOptionalParamBeforeDisplay(key, value) + '<br />';
        }
      }

    }

  }

  private populatePopupLane(evt: MessageModel, polyline: Polyline): void {

    this.popupContent.nativeElement.innerHTML = '';
    if (polyline.ssemExtra != null) {
      if (polyline.ssemExtra.laneStatus != null) {
        this.popupContent.nativeElement.innerHTML += '<b>Priority status: ' + polyline.ssemExtra.laneStatus.toUpperCase() + '</b><br />';
      }
    }

    if (evt.generationTime != null) {
      this.popupContent.nativeElement.innerHTML += evt.generationTime + '<br />';
    }
    this.popupContent.nativeElement.innerHTML += 'lane id: ' + polyline.spatemExtra.laneInfo.laneID + '<br />';
    this.popupContent.nativeElement.innerHTML += 'maneuvers: ' + polyline.spatemExtra.laneInfo.maneuvers + '<br />';
    this.popupContent.nativeElement.innerHTML += 'shared with: ' + polyline.spatemExtra.laneInfo.sharedWith;

  }

  private populatePopupTrafficLight(supportTrafficLight: SupportTrafficLightModel): void {

    this.popupContent.nativeElement.innerHTML = ' - state: ' + supportTrafficLight.eventState + '<br />';
    this.popupContent.nativeElement.innerHTML += ' - now (seconds): ' + supportTrafficLight.getNowTimeSeconds() + '<br />';
    this.popupContent.nativeElement.innerHTML += ' - reference time (seconds): '
      + supportTrafficLight.getIntersectionMoySeconds() + '<br />';

    this.popupContent.nativeElement.innerHTML += ' - likely change time (seconds): '
      + supportTrafficLight.getLikelyChangeTimeSeconds() + '<br />';

    this.popupContent.nativeElement.innerHTML += ' - status changes in (seconds): '
      + supportTrafficLight.getTimeToNextStateSeconds() + '<br />';

    this.popupContent.nativeElement.innerHTML += ' - next states: { <br />';

    let cnt = 1;
    supportTrafficLight.intersectionStates.stateTimeSpeed.forEach(sts => {
      this.popupContent.nativeElement.innerHTML += cnt + '. state: ' + sts.eventState + '<br />';
      this.popupContent.nativeElement.innerHTML += ' -- likely change time (seconds): ' + (sts.likelyTime / 10) + '<br />';
      this.popupContent.nativeElement.innerHTML += ' -- confidence: ' + sts.confidence + '<br />';

      cnt += 1;
    });
    this.popupContent.nativeElement.innerHTML += ' } ';
  }

  private populatePopupTrace(totalTraces: number, encoded: string): void {
    this.popupContent.nativeElement.innerHTML = ' - total traces (debug) ' + totalTraces + '<br/>';
    this.popupContent.nativeElement.innerHTML += ' - encoded (debug) ' + encoded + '<br/>';
    this.popupContent.nativeElement.innerHTML += ' - total length: ' + length + ' (meters)';
  }

  // Support functions for populatePopup functions ------------------------------------- //
  private isOptionalParamsToDisplay(key: string): boolean {

    switch (key.toLowerCase()) {
      case 'sensors': return false;

      // Note: add others if needed.....

      default: return true;
    }
  }

  private manageOptionalParamBeforeDisplay(key: string, value: string): string {

    let result = value;

    if (key.toLowerCase() === 'zone') {
      const zone = JSON.parse(value);
      result = ' <br /> - <b>id: ' +  ((zone['zoneId'] !== null) ? zone['zoneId'] : 'not set') + '</b><br />';
      result += ' - availables: ' + ((zone['nAvailables'] !== null) ? zone['nAvailables'] : 'not set') + '<br />';
      result += ' - occupied: ' + ((zone['nOccupieds'] !== null) ? zone['nOccupieds'] : 'not set') + '<br />';
      result += ' - reservable: ' + ((zone['nReserved'] !== null) ? zone['nReserved'] : 'not set') + '<br />';
      result += ' - unknown: ' + ((zone['nUnknowns'] !== null) ? zone['nUnknowns'] : 'not set');
    }

    // Note: add others keys if needed.....

    // Note: the value of every other key is returned as is (empty values are set to 'not set')

    return result !== '' ? result : 'not set';


  }

  /* *********************************************************************************** */

  // Setters functions ----------------------------------------------------------------- //
  resetEvents(events: MessageModel[]): void {
    this.events = events;
    this.eventsBaseItems = events;
  }

}

