import { Injectable } from '@angular/core';
import {Auth} from 'aws-amplify';
import {from, Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {map, switchMap} from 'rxjs/operators';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements CanActivate {
  private idTokenValue: string;

  constructor(private router: Router) {
    Auth.configure({
      aws_cognito_region: environment.cognitoRegion,
      aws_user_pools_id:  environment.cognitoPoolId,
      aws_user_pools_web_client_id: environment.cognitoClientId,
      aws_mandatory_sign_in: 'enable',
      storage: window.sessionStorage
    });
  }

  public login(username: string, password: string): Observable<boolean>{
    return this.isUserLoggedIn().pipe(
      switchMap(alreadyLoggedIn => {
        if ( alreadyLoggedIn ) {
          return of(true);
        }
        else {
          const p = Auth.signIn(username, password).then(res => Auth.currentSession()).then(res => {
            this.idTokenValue = res.getIdToken().getJwtToken();
            return true;
          }).catch(err => {
            return false;
          });

          return from(p);
        }

      })
    );
  }

  public logout(): Observable<boolean> {

    const p = Auth.signOut({global: true}).then(res => {
      return true;
    }).catch(err => {
      window.sessionStorage.clear();
      return true;
    });

    return from(p);
  }

  public isUserLoggedIn(): Observable<boolean>{

    const currentAuthentication = Auth.currentSession().then(res => {
      this.idTokenValue = res.getIdToken().getJwtToken();
      return true;
    }).catch(err => false);
    return from(currentAuthentication);
  }

  get idToken(): string{
    return this.idTokenValue;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.isUserLoggedIn().pipe(
      map(res => {
        if ( !res ) {
          this.router.navigate( [ 'login' ]);
        }
        return res;
      })
    );

  }
}
