<div #pageTop style="margin-bottom: 50px">
  <app-time-filter [values]="getTimeFilterValues()" [title]="authorityInfo.authorityFullName"
                   [index]="timeFilterIndex" [isLoading]="false"
                   (filterChanged)="filterChanged($event)" (refreshRequest)=" getAuthorityUseCases() ">
  </app-time-filter>

  <div class="main-body">

    <div class="alert alert-info horizontallyCenterContainerAndContent" role="alert" [style.display]="hiddenAlertTableContentIsLoading ? 'none': 'block'">
      <b>Loading data from the server...</b>
    </div>

    <div class="alert alert-danger horizontallyCenterContainerAndContent" role="alert" [style.display]="hiddenAlertDanger ? 'none': 'block'">
      <b>An unexpected error occurred while downloading data from the server.</b>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="useCase">
        <th mat-header-cell *matHeaderCellDef>Use case</th>
        <td class="clickable-cell" (click)=" navigateToUseCase(element) " mat-cell *matCellDef="let element"> {{element.useCase}} </td>
      </ng-container>

      <ng-container matColumnDef="msgType">
        <th mat-header-cell *matHeaderCellDef>Message type</th>
        <td mat-cell *matCellDef="let element"> {{element.messageType}} </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Total messages</th>
        <td mat-cell *matCellDef="let element"> {{element.totalMessages}} </td>
      </ng-container>

      <ng-container matColumnDef="success">
        <th mat-header-cell *matHeaderCellDef>Number of Successes</th>
        <td mat-cell *matCellDef="let element"> {{element.success}} </td>
      </ng-container>

      <ng-container matColumnDef="failures">
        <th mat-header-cell *matHeaderCellDef>Number of Failures</th>
        <td class="clickable-cell" (click)="setSelectedUseCase(element.useCase)" mat-cell *matCellDef="let element"> {{element.failures}} </td>
      </ng-container>

      <ng-container matColumnDef="datex-parsing">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td class="clickable-cell" mat-cell *matCellDef="let element">
          <button mat-button (click)=" navigateToTestingPage(element) " class="actionTest">
            <img src="assets/img/code-slash.svg" width="22" height="22" [matTooltip]="'Parse input for ' + element.useCase" alt=""/> Test
          </button>

          <!-- Handle download errors button -->
          <button *ngIf="!downloadingFailureMessagesIsLoading"  mat-button (click)=" downloadFailureMessages( element.useCase ) "
                  [class]="buttonDownloadFailureMessagesDisabled ?  'actionDownloadDisabled' :'actionDownload'"
                  [disabled]="element.failures === 0 || buttonDownloadFailureMessagesDisabled"
                  [style]="element.failures <= 0 ? 'background-color: #747983 !important' : ''">

            <img src="assets/img/download.svg" width="20" height="22"
                 [matTooltip]="element.failures <= 0 ? 'No failures available for download' : 'Download all failure messages for ' + element.useCase" alt=""/>
          </button>
          <button *ngIf="downloadingFailureMessagesIsLoading" mat-raised-button color="primary" [class]="'actionDownloadDisabled'"
                  [class.buttonSpinner]="true" [disabled]="true" style="text-decoration-color: transparent">
            <img src="assets/img/download.svg" width="20" height="22"/>
          </button>
          <!------------------------------------>

          <button mat-button *ngIf="element.totalMessages < 0" class="actionError">
            <img src="assets/img/exclamation-triangle_white.svg" width="22" height="22" [matTooltip]="'Error while downloading data for ' + element.useCase" alt=""/>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['useCase', 'msgType', 'total', 'success', 'failures', 'datex-parsing']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['useCase', 'msgType', 'total', 'success', 'failures', 'datex-parsing'];"></tr>
    </table>

    <!-- -------------------------- Failure messages -------------------------- -->
    <div class="failureMessages" *ngIf="selectedUseCase" #failureMessagesDiv>

      <!-- Section title and buttons -->
      <div style="display: inline-flex; width: 100%">
        <div style="display: inline; width: 80%;">
          <h4>Reasons of failure for use case: {{selectedUseCase}}</h4>
        </div>

        <div style="display: inline; margin-right: 10px;" class="widthFitContent">
          <a mat-button class="refresh"
             [class]="buttonRefreshFailuresDisabled ? 'refresh-disabled' : 'refresh'"
             (click)="refreshFailureMessages()"
             [disabled]="buttonRefreshFailuresDisabled"
             [matTooltip]="'Refresh failure messages for ' + selectedUseCase">
            <img src="assets/img/arrow-clockwise-white.svg" style="width: 15pt; height: 15pt" alt=""/>
          </a>
        </div>

        <!-- Handle download errors button -->
        <div style="display: inline; margin-right: 10px;" class="widthFitContent">
          <a *ngIf="!downloadingFailureMessagesIsLoading" mat-button (click)=" downloadFailureMessages( selectedUseCase )"
             [class]="buttonDownloadFailureMessagesDisabled ? 'downloadDisabled' : 'download'"
             [disabled]="buttonDownloadFailureMessagesDisabled"
             [matTooltip]="buttonDownloadFailureMessagesDisabled ? 'No failures available for download' : 'Download all failures for ' + selectedUseCase
             + ', since date: ' + lastDate">Download
            <img src="assets/img/download.svg" style="width: 15pt; height: 15pt" alt=""/>
          </a>
          <a *ngIf="downloadingFailureMessagesIsLoading" mat-button class="downloadDisabled" [disabled]="true">
            <p style="display: inline">Downloading</p>
            <a mat-raised-button color="primary" [class]="'actionDownloadDisabled'" [class.buttonSpinner]="true" style="display: inline"></a>
          </a>

        </div>
        <!------------------------------------>

        <div style="display: inline;" class="widthFitContent">
          <a mat-button class="close" (click)="closeFailureMessages()">Close <img src="assets/img/cancel-white.svg" style="width: 15pt; height: 15pt" alt=""/></a>
        </div>
      </div>

      <div class="alert alert-warning horizontallyCenterContainerAndContent" role="alert" [style.display]="'block'">
        <b>To access more than the latest {{ lastNMessages }} failures, click the <u>Download</u> button.</b>
      </div>

      <!-- Search bar -->
      <mat-form-field class="form-input">
        <mat-label>Filter failures messages...</mat-label>
        <input matInput (keyup)=" filterTableChanged($event.target.value) " placeholder="Filter failure messages" #filterTableInputField>
        <mat-icon matSuffix (click)=" resetTableFilter() ">close</mat-icon>
      </mat-form-field>

      <!-- Loading spinner animation -->
      <button mat-button type="button" [disabled]="true" class="spinner" [class.spinner]="failureMessagesIsLoading">
      </button>

      <!-- Failure messages -->
      <div *ngIf="!failureMessagesIsLoading" >
        <table mat-table [dataSource]="dataSourceFailureMessages" *ngIf="!failureMessagesIsLoading" class="mat-elevation-z8 failureMessagesTable" style="width: 100%">

          <ng-container matColumnDef="data">

            <div mat-cell *matCellDef="let element" class="disableMatCellUpperCase failureMessagesTableRow">
              <div class="toggle-children" style="height: 40px">
                <h4 class="failureMessageGroupTitle">
                  Message Identifier: {{ element.getSituationRecordId() != null ? element.getSituationRecordId() : 'undefined' }}
                </h4>
                <mat-icon class="failureMessageGroupToggle" (click)=" toggleElement(element) " >
                  {{ element.visible ? 'arrow_drop_up' : 'arrow_drop_down' }}
                </mat-icon>
              </div>

              <div *ngIf="element.visible" style="padding-left: 10px; padding-right: 10px">
                <div *ngFor="let failureMsg of element.failureMessages" class="failureMessageGroupBody sidebar-body" [class]="failureMsg.situationRecordID != null ? 'error': '' ">

                  <ng-scrollbar track="horizontal">
                    <!-- Note: do not indent the content of <pre> + new lines are replicated in the displayed page -->
                    <pre>
Date: {{ failureMsg.date }},
Error: {{ failureMsg.reasonOfFailure }}
                    </pre>

                  </ng-scrollbar>
                </div>
              </div>
            </div>

          </ng-container>

          <tr mat-row *matRowDef="let row; columns: ['data'];" class=""></tr>

        </table>

        <!-- Note: if mat-paginator is in an hidden container, it does not get initialized when the page loads.
         Use setTimeout to properly load it before using it. -->
        <mat-paginator #paginatorFailureMessages
                       [pageSize]="10"
                       showFirstLastButtons
                       aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
    <!-- -------------------------------------------------------------------- -->

    <button mat-button (click)="scrollToTop()" class="back-to-top" matTooltip="Back to Top" *ngIf="showScrollUpAndDownButton">
      <mat-icon>arrow_upward</mat-icon>
    </button>
    <button mat-button (click)=" scrollToBottom() " class="go-to-bottom" matTooltip="Go to last item" matTooltipPosition="left" *ngIf="showScrollUpAndDownButton">
      <mat-icon>arrow_downward</mat-icon>
    </button>
  </div>
</div>


<div #pageBottom [class]="showScrollUpAndDownButton ? 'pageBottom' : ''"></div>
