<div>
  <app-time-filter [values]="getTimeFilterValues()" [index]="timeFilterIndex" [title]="title" [isLoading]="timeFilterIsLoading"
                   (filterChanged)="filterChanged($event)" (refreshRequest)="refreshPageContent()">
  </app-time-filter>

  <div style="width: min-content; margin-left: auto; margin-right: auto">

    <div class="alert alert-info horizontallyCenterContainerAndContent" role="alert" [style.display]="hiddenAlertTableContentIsLoading ? 'none': 'block'">
      <b>Loading data from the server...</b>
    </div>

    <div class="alert alert-danger horizontallyCenterContainerAndContent" role="alert" [style.display]="hiddenAlertDanger ? 'none': 'block'">
      <b>An unexpected error occurred while downloading data from the server.</b>
    </div>

    <div class="centerContent">
      <div class="dashboard-body">
        <div class="centerContent">
          <h4 class="mainChart">ALL</h4>
          <app-dashboard-chart [authorityStats]="totalStats" [view]="[1000, 300]">
          </app-dashboard-chart>
        </div>
      </div>

      <div class="centerContent">
        <mat-tab-group class="stats" (dblclick)="openAuthorityMain()" (selectedTabChange)="selectionChanged($event)">

          <mat-tab *ngFor="let cityData of statisticsData;" label="{{ cityData.authorityName }}" >
            <h4 (click)="openAuthorityMain()" class="clickable">
              {{ cityData.authorityName }}
              <img src="../../assets/img/box-arrow-up-right.svg"  alt=""/>
            </h4>
            <app-dashboard-chart [authorityStats]="cityData" [view]="[600, 200]">
            </app-dashboard-chart>
          </mat-tab>

        </mat-tab-group>
      </div>
    </div>
  </div>

</div>
