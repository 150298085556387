import {ChangeDetectorRef, Component, DoCheck, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TimeFilter} from '../model/time.filter.model';
import {animate, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MessagesService} from '../service/messages.service';
import {interval, Subscription} from 'rxjs';
import {EventMapComponent} from '../event-map/event-map.component';
import {MessageModel} from '../model/message.model';
import {MessageResponseModel} from '../model/message.response.model';
import {StatusOfDeliveryModel} from '../model/status-of-delivery.model';
import {AppComponent} from '../app.component';
import {SidebarItemComponent} from '../sidebar-item/sidebar-item.component';
import {Title} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {getFormattedDate, getFormattedDateTimeFilter} from '../util';
import {DialogData} from '../dialog/dialog.data';
import {PolylineTypeLegend} from '../model/polyline-type-legent.model';
import {PoizoneModel} from '../model/poizone.model';
import {MapData} from '../model/map.data.model';
import {Coordinate} from '../model/coordinate.model';
import {UtilityFunctions} from '../service/utility-functions';

@Component({
  selector: 'app-authority-use-case',
  templateUrl: './authority-use-case.component.html',
  styleUrls: ['./authority-use-case.component.css'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({opacity: 0}),
        animate(500, style({opacity: 1}))
      ]),
      transition('* => void', [
        animate(200, style({opacity: 0}))
      ])
    ])
  ]
})
export class AuthorityUseCaseComponent extends UtilityFunctions implements OnInit, OnDestroy, DoCheck {

  // Note: @ViewChild(EventMapComponent) means that this component is present in the HTML as <app-time-filter ...></...>
  @ViewChild(EventMapComponent) eventMapComponent: EventMapComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filterTableInputField') searchInputField: ElementRef;

  mapEvents: MessageModel[];

  // Handle message shown in the TextArea
  messageToDisplay: MessageModel = null;
  // Handle selected message from the table
  selectedMsg: MessageModel = null;
  // Handle message shown in the map
  mapShownMsg: MessageModel = null;

  selectedZoneId: string = null;
  dataSource: MatTableDataSource<MessageModel>;
  messages: MessageModel[];
  zones: Map<string, PoizoneModel>;
  messagesBeforeFilter: MessageModel[];
  messageContent: MessageContent[];

  private authorityName: string;
  private filterMessageIdentifier: string;
  filteredParkingSpaceCounter: number; // number of Parking spaces matching the selected filter criteria

  authorityShortName: string;
  authorityLocation: number[];
  useCase: string;
  inputFormat: string;
  messageType: string; // IVIM, DENM, SPATEM, MAPEM, POI

  // Button within the legend component
  polylineTypeLegends: PolylineTypeLegend[];
  legendSelectedType: PolylineTypeLegend;

  lastDate = '2004-01-01T00:00:00Z';
  formattedLastDate: string;

  // Only used during page initialization
  timeFilterSelected: TimeFilter;
  timeFilterIndex: number;
  pageTitle = 'Authority Use-case';

  title: string;

  // Alerts and SnackBar configurations
  hiddenAlertWarning = true;
  hiddenAlertDanger = true;
  showFiltersWarning = false;
  warningMessage: string = null;
  // Warning messages
  selectFilterWarningMsg = 'Select a message before applying filters.';
  selectedMessageNotPresentAnymoreWarningMsg = 'Previously selected message is not valid anymore. Time-filter-interval has changed.';

  useCaseMessagesIsLoading = true;

  // Page refresh setup
  refreshPageTimer: Subscription;


  constructor(private router: Router, private activeRoute: ActivatedRoute,
              private titleService: Title,
              private messagesService: MessagesService,
              private appComponent: AppComponent,
              private sidebarItemComponent: SidebarItemComponent,
              private cdr: ChangeDetectorRef,
              private dialog: MatDialog,
              private snackBar: MatSnackBar)
  {
    super();
    this.titleService.setTitle(this.pageTitle);
  }

  ngOnInit(): void {

    this.messages = [];
    this.messagesBeforeFilter = [];
    this.mapEvents = [];
    this.messageContent = [];
    this.polylineTypeLegends = [];
    this.legendSelectedType = null;
    this.filterMessageIdentifier = '';
    this.filteredParkingSpaceCounter = -1;
    this.zones = new Map<string, PoizoneModel>();

    this.timeFilterSelected = TimeFilter.LAST_WEEK;
    this.getAppComponentDateFilterParam();

    this.lastDate = this.computeUTCDate(this.timeFilterSelected);
    this.formattedLastDate = getFormattedDateTimeFilter(this.timeFilterSelected);
    this.timeFilterIndex = this.getTimeFilterIndex();

    this.authorityShortName = this.activeRoute.snapshot.params.authorityName;
    this.useCase = this.activeRoute.snapshot.params.useCase;

    this.getUseCaseMessages();
    [this.messageType, this.polylineTypeLegends] = this.manageMapLegend(this.useCase);

    // Update data every 1 minute
    this.refreshPageTimer = interval(60000).subscribe(val => {
      this.updateUseCaseMessages();
    });
  }

  ngOnDestroy(): void {
    this.refreshPageTimer.unsubscribe(); // stop the timer
  }

  computeUTCDate(date): string {
    // Note: today's date is computed as "yyyy-mm-YESTERDAY T 23:00:00 Z"
    // Note: it also generates milliseconds (e.g., 2015-12-02T21:45:22.279Z)
    const dateUTC = new Date(date).toISOString();
    const tmpDate = dateUTC.split('.');

    return tmpDate[0] + 'Z';
  }

  /* Only needed for POI messages. Extract from optionalParams the list of sensors (parking locations)
  * Create a new MessageModel for each sensor */
  private convertMessage(msg: MessageModel): MessageModel[] {

    let msgs = [ msg ];

    if (msg.optionalParams !== null) {
      if ( msg.optionalParams['sensors'] ){

        const sensors = JSON.parse(msg.optionalParams['sensors']);
        if ( sensors.length > 0 ){

          msgs = [];
          for ( const s of sensors ){

            const optionalParams: Map<string, string> = new Map<string, string>();
            optionalParams['id'] = s.id;
            optionalParams['status'] = s.sensorStatusEnum;
            optionalParams['type'] = s.sensorTypeEnum;

            const m = new MessageModel(s.id, s.id, [], this.authorityName, msg.generationTime, new StatusOfDeliveryModel(false, false),
              new MapData(new Coordinate(s.position.latitude, s.position.longitude), [], 'POI', ''), optionalParams);

            msgs.push(m);

          }
        }
      }
    }
    return msgs;

  }

  /** Get the map of all zones and extract the one containing the input msg */
  private filterZonesByMsg(msg: MessageModel): Map<string, PoizoneModel> {

    const singleZoneMap: Map<string, PoizoneModel> = new Map<string, PoizoneModel>();

    // Note: msg.id = zoneId|cityShortName -- es. 1RB|TN
    const msgZoneId = msg.id;

    if (msgZoneId != null) {
      singleZoneMap[msgZoneId] = this.zones[msg.id];
      return singleZoneMap;
    }
    else {
      return this.zones;
    }

  }

  private populateTable(messageResponseModel: MessageResponseModel): MessageResponseModel {

    this.messages = messageResponseModel.messages;
    this.messagesBeforeFilter = this.messages;
    this.zones = new Map<string, PoizoneModel>();

    this.validateSelectedMsgAndMsgToDisplay( this.messages );

    if (this.messages.length === 0 && !this.useCaseMessagesIsLoading) {
      this.hiddenAlertWarning = false;
    }
    else {
      this.hiddenAlertWarning = true;

      for (const msg of this.messages) {
        msg.statusOfDelivery = new StatusOfDeliveryModel(true, true);
      }
    }

    const res: MessageResponseModel = new MessageResponseModel( this.authorityShortName, this.messages );

    // Sort messages by date (DESC) and by id (ASC) ---------------------
    // Note: "generationTime" is set by the backend, according to the value of "denm.denm.management.getDetectionTime()"
    res.messages.sort( (a, b) => {
      if (a.generationTime === b.generationTime) {
        return a.messageIdentifier > b.messageIdentifier ? 1 : -1;
      }
      return a.generationTime <= b.generationTime ? 1 : -1;
    });
    // -----------------------------------------------------

    // update the datasource and refresh the linked table
    this.dataSource = new MatTableDataSource<MessageModel>(res.messages);

    // update table content
    this.filterTableChanged( this.filterMessageIdentifier, false );

    this.convertPOIOptionalParamToZones();

    return res;
  }

  private populateMap(msg: MessageModel): void {

    this.eventMapComponent.clearMap();
    this.eventMapComponent.isMapInLocationOnlyMode = false; // change the map's mode
    // update the list of events - convertMessage is used by POI to display each Parking location
    this.eventMapComponent.resetEvents(this.convertMessage(msg));

    this.eventMapComponent.zones = this.filterZonesByMsg(msg);
    this.eventMapComponent.setSelectedRadius(this.messageType); // parameter used to set the event area radius on the map
    this.eventMapComponent.populateMap(); // show the updated events on the map
  }

  private populateMapLocationsOnly(): void {
    // Display a marker for each event's location
    this.eventMapComponent.resetEvents(this.messages); // update the list of events
    this.eventMapComponent.zones = this.zones;
    this.eventMapComponent.populateMapLocationsOnly(); // show the updated events on the map
  }

  private refreshTableContent(): void {
    const source = new MatTableDataSource<MessageModel>(this.dataSource.data);
    source.paginator = this.paginator;
    this.dataSource = source;
  }

  filterChanged($event: TimeFilter): void {
    // Function called when the user changes the Time Period

    this.selectedMsg = null;
    this.mapShownMsg = null;
    this.messageToDisplay = null;
    this.filterMessageIdentifier = '';

    this.timeFilterSelected = $event;
    this.lastDate = this.computeUTCDate(this.timeFilterSelected);
    this.formattedLastDate = getFormattedDate(this.lastDate);
    this.timeFilterIndex = this.getTimeFilterIndex();
    this.title = this.authorityName + ' ( ' + this.useCase + ' ) from date: "' + this.formattedLastDate + '"';

    // used to propagate information related to selected timeFilter
    this.sidebarItemComponent.updateQueryParams(this.timeFilterIndex);
    this.resetTableFilter();

    // Clear the map
    this.eventMapComponent.clearMap();

    // Load data for the specified period
    this.getUseCaseMessages();

  }

  // Called when the page is initiated and when the Time filter is used
  getUseCaseMessages(): void {
    this.getUseCaseMessagesFiltered(null);
  }

  getUseCaseMessagesFiltered(customHeaders: Map<string, string>): void {

    // When the table content reloads, remove the displayed error or warning and show the loading message
    this.hiddenAlertWarning = true;
    this.useCaseMessagesIsLoading = true;
    this.hiddenAlertDanger = true;

    // Download data from the server
    this.messagesService.getMessages(this.authorityShortName, this.useCase, this.lastDate, customHeaders).subscribe(messagesResponse => {

        this.useCaseMessagesIsLoading = false;
        if ( messagesResponse == null ) {
          this.hiddenAlertDanger = false;
        }
        else {
          this.hiddenAlertDanger = true;

          this.authorityName = messagesResponse.authorityName;
          this.inputFormat = messagesResponse.inputFormat;
          this.title = this.authorityName + ' ( ' + this.useCase + ' ) from date: "' + this.formattedLastDate + '"';

          this.populateTable(messagesResponse);

          // for each location, display a marker
          this.populateMapLocationsOnly();
          this.setAuthorityLocation(); // center the map according to the current authority coordinates
        }


      },
      error => {
        this.title = this.authorityShortName + ' ( ' + this.useCase + ' ) from date: "' + this.formattedLastDate + '" has generated an error';

        this.hiddenAlertWarning = true;
        this.useCaseMessagesIsLoading = false;
        this.hiddenAlertDanger = false;

      });
  }

  getTimeFilterValues(): TimeFilter[]{
    return [ TimeFilter.TODAY, TimeFilter.LAST_WEEK, TimeFilter.LAST_MONTH, TimeFilter.LAST_YEAR, TimeFilter.ALL ];
  }

  getTimeFilterIndex(): number {

    const filters = this.getTimeFilterValues();

    for (let i = 0; i < filters.length; i++) {
      if (filters[i] === this.timeFilterSelected) {
        return i;
      }
    }

    return 1; // return default value
  }

  private getAppComponentDateFilterParam(): void {

    // Check if the AppComponent contains a valid 'dateFilter'
    const dateFilter = this.appComponent.getDateFilterParam();

    if (dateFilter != null) {
      if (!isNaN(dateFilter)) {
        const dateIndex = dateFilter;
        if (dateIndex >= 0 && dateIndex < this.getTimeFilterValues().length) {
          this.timeFilterSelected = this.getTimeFilterValues()[dateIndex];
          this.timeFilterIndex = dateFilter;
        }
      }
    }
  }

  private handleZoneUpdate(msg: MessageModel, zones: Map<string, PoizoneModel>): Map<string, PoizoneModel> {

    if (msg !== null) {
      if (msg.optionalParams !== null) {
        if ( msg.optionalParams['zone'] !== undefined ) {
          const optionalParamsZone = JSON.parse( msg.optionalParams['zone'] );
          const zoneId = optionalParamsZone['zoneId'];

          if (zoneId !== null) {

            if (zoneId !== this.selectedZoneId) {
              this.selectedZoneId = zoneId;
            }

            // filter zones - only show the ones linked to "this.messages"
            const filteredZones = new Map<string, PoizoneModel>();
            filteredZones[this.selectedZoneId] = this.zones[this.selectedZoneId];
            return filteredZones;
          }
        }
      }
    }

    return zones;
  }

  // Function called when the user click the "marker" in a table's row
  mapClick(element: MessageModel): void {

    const elementMsgIdentifier = element.messageIdentifier;

    if (this.mapShownMsg && this.mapShownMsg.messageIdentifier === elementMsgIdentifier)
    { // reload all the messages

      this.eventMapComponent.isMapInLocationOnlyMode = true;
      this.eventMapComponent.resetEvents(this.messages); // update the list of events
      this.eventMapComponent.zones = this.zones;
      this.eventMapComponent.populateMapLocationsOnly();
      this.setAuthorityLocation();
      this.selectedMsg = null;
      this.mapShownMsg = null;
    }
    else { // load selected message

      this.selectedMsg = element;
      this.mapShownMsg = element;
      this.legendSelectedType = null;

      this.eventMapComponent.elem.nativeElement.scrollIntoView({
        behavior: 'smooth'
      });

      this.populateMap(element);
    }
  }

  openDialog(useCase: string): void {
    let content = [];
    if (useCase === 'SPATEM' || useCase === 'MAPEM' || useCase === 'SI-TLP') {

      content = ['Click a lane to display info', 'Double click a lane to display connections with other lanes',
        'Click "SPATEM MAPEM", "INGRESS LANE" or "EGRESS LANE" to filter the map and display the selected type of lanes'];
    }
    else if (useCase === 'PI-ONSTREET') {
      content = ['Click a parking icon to view details',
        'Select a parking from the table to display contained spaces',
        'Use filters to filter parking spaces by Availability or Type' ];
    }

    this.dialog.open(DialogComponent, {
      data: new DialogData('How to interact with the map:', content) });
  }

  openSnackBarDataUpdate(): void {
    this.openSnackBar('Data has been updated', 3000);
  }

  openSnackBar(text: string, milliseconds: number): void {
    const snackBarRef = this.snackBar.open(text, 'Close', {
      duration: milliseconds,
      verticalPosition: 'bottom'
    });

    // In order to show multiple messages sequentially
    // snackBarRef.afterDismissed().subscribe(() => {
    //   this.openSnackBarWarning(text + ' ' + text);
    // });
  }

  populateMapEvent(): void {
    // filters have been removed from the map
    this.legendSelectedType = null;
  }

  /* Function triggered when the user clicks on the map.
  * This function is called by the event emitted by the event-map */
  selectedMarkerChanged(messageModel: MessageModel): void {

    // when the user click on a marker in LocationOnlyMode, change the corresponding row icon in the table
    if (messageModel) {

      /* If the map is in "location only mode", then we need to update the selected message and the mapShownMsg
      * Otherwise, the selected message did not change.
      * For usecases like POI, the mapShownMsg may have changed. */
      if ( this.eventMapComponent.isMapInLocationOnlyMode) {
        this.selectedMsg = messageModel;
        this.mapShownMsg = messageModel;
      }
      else {
        this.mapShownMsg = messageModel;
      }
    }
  }

  setAuthorityLocation(): void {

    switch ( this.authorityShortName.toUpperCase() ) {
      // Note: coordinates taken from C-ITS server TM
      case 'TO': this.authorityLocation = [ 7.6778578, 45.0715141 ]; break;
      case 'VR': this.authorityLocation = [ 10.9933797, 45.4380689 ]; break;
      case 'TN': this.authorityLocation = [ 11.1191495, 46.0690513 ]; break;
      case 'A4': this.authorityLocation = [ 10.5847739, 45.5688172 ]; break;
      default: this.authorityLocation = [ 0, 0]; break;
    }

    this.eventMapComponent.centerMapOnLocationAndResetZoom(this.authorityLocation);
  }

  setLegendButtonStyle(polylineTypeLegend: PolylineTypeLegend): string {

    const styleClass = 'polylineTypeBtn' + polylineTypeLegend.styleName;

    if ( this.legendSelectedType !== null && polylineTypeLegend.type === this.legendSelectedType.type ) {
      return styleClass + 'Selected';
    }
    else {
      return styleClass;
    }

  }

  togglePolylines(polylineType: PolylineTypeLegend): void {

    if ( !this.selectedMsg || !this.mapShownMsg || this.eventMapComponent.isMapInLocationOnlyMode) {
      this.showFiltersWarning = true;
      this.warningMessage = this.selectFilterWarningMsg;
    }
    else {
      this.showFiltersWarning = false;

      if (this.legendSelectedType !== polylineType) { // new / different filter activated
        this.legendSelectedType = polylineType;
        this.filteredParkingSpaceCounter = this.eventMapComponent.filterMap(polylineType.type, false);
      }
      else { // already selected filter has been clicked to be deactivated
        this.legendSelectedType = null;
        this.filteredParkingSpaceCounter = this.eventMapComponent.filterMap(polylineType.type, true);
      }

      /* if there are no filtered parking spaces, replace the 'mapShownMsg'
      * with 'selectedMsg' in order to update the "Selected message" name */
      if (this.filteredParkingSpaceCounter <= 0) {
        this.mapShownMsg = this.selectedMsg;
      }
    }

  }

  validateSelectedMsgAndMsgToDisplay(messages: MessageModel[]): void {
    // check if the selected message and the displayed message are still present in the updated messages received from the server.
    // if yes, keep them selected in the table; otherwise, deselect them

    let selectedMsgStillPresent = false;
    let msgToDisplayStillPresent = false;

    for (const msg of messages) {
      // debug: console.debug('A > ' + (this.selectedMsg !== null) + ' && '
      // + (!selectedMsgStillPresent) + ' = ' + (this.selectedMsg !== null && !selectedMsgStillPresent));
      if ( this.selectedMsg !== null && !selectedMsgStillPresent ) {

        // debug: console.debug('B > ' + (msg.messageIdentifier) + ' === ' + (this.selectedMsg.messageIdentifier)
        //   + ' = ' + (msg.messageIdentifier === this.selectedMsg.messageIdentifier));
        if (msg.messageIdentifier === this.selectedMsg.messageIdentifier) {
          selectedMsgStillPresent = true;
        }
      }

      if ( this.messageToDisplay !== null && !msgToDisplayStillPresent ) {
        if (msg.messageIdentifier === this.messageToDisplay.messageIdentifier) {
          msgToDisplayStillPresent = true;
        }
      }
    }

    if ( !selectedMsgStillPresent ) {
      this.selectedMsg = null;
      this.mapShownMsg = null;
    }
    if ( !msgToDisplayStillPresent ) {
      this.messageToDisplay = null;
    }
  }

  updateDisplayMessage(message: MessageModel): void{
    this.messageContent = [];

    if ( this.messageToDisplay === null || this.messageToDisplay.id !== message.id) {
      this.messageToDisplay = message;

      this.messageToDisplay.messageContent.forEach(msg => {
        this.messageContent.push(new MessageContent(msg, true));
      });

      if (this.messageToDisplay.messageContent.length === 0) {
        this.messageContent.push(new MessageContent('This message has empty content.', true));
      }
    }
    else if ( this.messageToDisplay.id === message.id) {
      this.messageToDisplay = null;
    }

  }

  updateDisplayMessageAndClearMap(message: MessageModel): void{
    this.updateDisplayMessage(message);
    this.eventMapComponent.clearMap();
  }

  updateUseCaseMessages(): void {
    // Called every 1 minute by the timer defined in the ngInit, and when the user request a page refresh
    console.log('Updating use-case data: ' + new Date().toLocaleString());

    // When the table content reloads, remove the displayed error or warning and show the loading message
    this.hiddenAlertWarning = true;
    this.useCaseMessagesIsLoading = true;
    this.hiddenAlertDanger = true;

    // Download data from the server
    this.messagesService.getMessages(this.authorityShortName, this.useCase, this.lastDate, null).subscribe(messagesResponse => {

        // Show Toast message whenever data are refreshed. Hide the Toast after 3 seconds
        this.openSnackBarDataUpdate();

        const oldMapShownMessage = this.mapShownMsg;

        // TODO: this function is sets this.selectedMsg to null when a POI single parking event popup is open
        this.populateTable(messagesResponse);

        // debug: console.debug('selectedMsg: ' + (this.selectedMsg !== null ? this.selectedMsg.messageIdentifier : 'null'));
        // debug: console.debug('mapShownMsg: ' + (this.mapShownMsg !== null ? this.mapShownMsg.messageIdentifier : 'null'));

        // If the map is displaying multiple messages locations
        if ( this.eventMapComponent.isMapInLocationOnlyMode ) {
          this.populateMapLocationsOnly();
          this.eventMapComponent.updateZoomMap();
        }
        // If the map is displaying a single event, update the map
        else if (this.mapShownMsg !== null) {
          messagesResponse.messages.forEach(msg => {

            // doing this for POI because message id is "zone|authority" and stalls id are "zone|stall|authority"
            if (msg.messageIdentifier === this.mapShownMsg.messageIdentifier ) {

              // Update what's displayed on the map
              // convertMessage is used by POI to display each Parking location
              this.eventMapComponent.resetEvents(this.convertMessage(msg));
              if (msg.messageIdentifier === this.mapShownMsg.messageIdentifier){
                this.mapShownMsg = msg;
              }
              else {
                this.eventMapComponent.events.forEach(evt => {
                  if (evt.messageIdentifier === this.mapShownMsg.messageIdentifier){
                    this.mapShownMsg = msg;
                  }
                });
              }

              // Update zone or load the correct one
              this.eventMapComponent.zones = this.handleZoneUpdate(this.mapShownMsg, this.zones);

              // Update map content
              this.eventMapComponent.updateMap();
            }
          });
        }
        // If there was a selected message and is not present anymore, update the map
        else if (oldMapShownMessage !== null) {
          this.showFiltersWarning = true;
          this.warningMessage = this.selectedMessageNotPresentAnymoreWarningMsg;

          // Update map content and only show location only events
          this.populateMapLocationsOnly();
        }

        // If a message content is displayed, update it
        if (this.messageToDisplay !== null ) {

          this.messageContent = [];
          messagesResponse.messages.forEach( msg => {

            if ( msg.id === this.messageToDisplay.id ) { // TODO: questo impedisce al POPUP dei POI di essere aggiornato dinamicamente...
              msg.messageContent.forEach( msgContent => {
                this.messageContent.push(new MessageContent(msgContent, true));
              });
            }
          });
        }

        // Hide messages
        this.useCaseMessagesIsLoading = false;
        this.hiddenAlertDanger = true;

      },
      error => {
        this.useCaseMessagesIsLoading = false;
        this.hiddenAlertWarning = true;
        this.hiddenAlertDanger = false;
      });
  }

  ngDoCheck(): void {
    this.cdr.detectChanges();
  }

  setTableSelectedMessagePinStyle(element: MessageModel): string {

    if (this.selectedMsg !== null) {
      return 'assets/img/' + (this.selectedMsg.id === element.id ? 'pin-filled.svg' : 'pin.svg');
    }
    else {
      return 'assets/img/pin.svg';
    }
  }

  setTableSelectedMessageToDisplayStyle(element: MessageModel): string {

    if (this.messageToDisplay !== null) {
      return 'assets/img/' + (this.messageToDisplay.id === element.id ? 'view-filled.svg' : 'view.svg');
    }
    else {
      return 'assets/img/view.svg';
    }
  }

// Called when the time-interval changes or the user filters the table by "Message Identifier"
  filterTableChanged(filterStr: string, applyFilter: boolean): void {

    this.filterMessageIdentifier = filterStr;
    this.messages = this.messagesBeforeFilter; // restore initial data

    this.messages = this.messages.filter( message =>
      (message.messageIdentifier.indexOf( this.filterMessageIdentifier ) !== -1)
    ); // filter data

    // filter zones - only show the ones linked to "this.messages"
    const filteredZones = new Map<string, PoizoneModel>();
    for (const [key, value] of Object.entries(this.zones)) {
      if (key.indexOf(filterStr) >= 0) {
        filteredZones[key] = this.zones[key];
      }
    }

    this.dataSource = new MatTableDataSource<MessageModel>( this.messages ); // update table content
    this.refreshTableContent();

    if ( applyFilter ) {
      // when the user applies a filter, clear the map and the selected rows of the table
      this.messageToDisplay = null;
      this.selectedMsg = null;
      this.mapShownMsg = null;
      this.eventMapComponent.closePopup();

      // When the table get filtered, display the matching events on the map (using Location Only Mode = true)
      this.eventMapComponent.isMapInLocationOnlyMode = true;
      this.eventMapComponent.resetEvents(this.messages);
      this.eventMapComponent.zones = filteredZones;
      this.eventMapComponent.populateMapLocationsOnly();
      this.setAuthorityLocation();

    }

    this.hiddenAlertWarning = (this.messages.length !== 0);

  }

// Called when the user close the filtering of the table by "Message Identifier"
  resetTableFilter(): void {
    this.searchInputField.nativeElement.value = '';
    this.filterMessageIdentifier = '';

    this.messages = this.messagesBeforeFilter;
    this.dataSource = new MatTableDataSource<MessageModel>( this.messages ); // update table content
    this.refreshTableContent();

    this.hiddenAlertWarning = (this.messages.length !== 0);

    if ( this.eventMapComponent.isMapInLocationOnlyMode ) {
      this.eventMapComponent.resetEvents(this.messages); // update the list of events
      this.eventMapComponent.zones = this.zones;
      this.eventMapComponent.populateMapLocationsOnly();
      this.setAuthorityLocation();
    }

  }

  // Only for usecase POI (PI) - convert optional parameter "sensors" to List of Events
  convertPOIOptionalParamToZones(): void {

    this.zones.clear();
    this.messages.forEach(msg => {

      if (msg.optionalParams !== null) {
        const zoneString = msg.optionalParams['zone'];

        if (zoneString){
          // Convert string zone to list of zones
          this.zones[msg.id] = JSON.parse(zoneString);
        }
      }
    });
  }

  /** IMPORTANT: some useCases use different "names" in the FRONTEND. Use this function to MAP FE names with BACKEND */
  navigateToTestingPage(): void {
    this.navigatePage(this.router, this.authorityShortName, this.inputFormat, this.useCase);
  }

}

// Support class for visualization purposes ----------------------------------------------------
class MessageContent {
  content: string;
  visible: boolean;

  constructor(content: string, visible: boolean) {
    this.content = content;
    this.visible = visible;
  }
}

