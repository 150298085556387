<div class="item">
  <div class="sidebar-item-title" (click)="onClick()">
    <div class="normal {{isSelected() ? 'selected' : ''}}" *ngIf="!compact" (mouseenter)="onEnter()" (mouseleave)="onOut()">

      <!-- Menu item Image, shortName, fullName -->
      <img (mouseenter)="onEnter()" [src]="'/assets/img/'+ getIcon(itemInfo.icon)" height="30" width="30" *ngIf="itemInfo.icon" alt="">
      <span (mouseenter)="onEnter()" class="subIcon" *ngIf="!itemInfo.icon">{{itemInfo.compactName}}</span>
      <h4 class="menuItemName three-dots" matTooltip="{{itemInfo.name}}">{{itemInfo.name}}</h4>

      <!-- toggle submenu button -->
      <span class="toggle-children" (click)="toggleChildren($event)" *ngIf="itemInfo.childItems.length > 0">
         <mat-icon>{{showChildren ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
      </span>
    </div>

    <!-- Manage closed sidebar -->
    <div class="compact {{isSelected() ? 'selected' : ''}}" *ngIf="compact"  (mouseenter)="onEnter()" (mouseleave)="onOut()">
      <img [src]="'/assets/img/'+ itemInfo.icon + (hover ? '_hover.svg' : '.svg')" height="50" width="30" *ngIf="itemInfo.icon" (mouseenter)="onEnter()">
      <p (mouseenter)="hover=true" class="subIconCompact" *ngIf="!itemInfo.icon">{{itemInfo.compactName}}</p>
    </div>
  </div>

  <!-- slider menu: submenu component -->
  <div class="sidebar-item-subitems" [@fade] *ngIf="!compact && showChildren">
    <app-sidebar-item
      *ngFor="let child of itemInfo.childItems" [itemInfo]="child" [compact]="compact">
    </app-sidebar-item>
  </div>

</div>

