export class LaneConnections {
  laneID: number;
  connectedTo: number[];
  connectionPoint: number[];

  constructor( laneID: number, connectedTo: number[], connectionPoint: number[]) {
    this.laneID = laneID;
    this.connectedTo = connectedTo;
    this.connectionPoint = connectionPoint;
  }
}
