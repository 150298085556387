import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TimeFilter} from '../model/time.filter.model';
import {ActivatedRoute, Router} from '@angular/router';
import {StatisticsModel} from '../model/statistics.model';
import {map} from 'rxjs/operators';
import {AuthoritiesService} from '../service/authorities.service';
import {StatisticsService} from '../service/statistics.service';
import {PermissionResponseModel} from '../model/permission.response.model';
import {FailureMessagesService} from '../service/failure-messages.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {interval, Subscription} from 'rxjs';
import {SidebarItemComponent} from '../sidebar-item/sidebar-item.component';
import {UseCaseDataModel} from '../model/use-case.data.model';
import {AuthorityInfoModel} from '../model/authority-info.model';
import {AppComponent} from '../app.component';
import {Title} from '@angular/platform-browser';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FailureMessageModel} from '../model/failuremessage.model';
import {FailureMessageVisualizationModel} from '../model/failuremessage.visualization.model';
import {getFormattedDate} from '../util';
import {UtilityFunctions} from '../service/utility-functions';

@Component({
  selector: 'app-authority-main',
  templateUrl: './authority-main.component.html',
  styleUrls: ['./authority-main.component.css']
})
export class AuthorityMainComponent extends UtilityFunctions implements OnInit, OnDestroy {

  @ViewChild('paginatorFailureMessages') paginator: MatPaginator;
  @ViewChild('pageTop', { read: ElementRef }) pageTop: ElementRef;
  @ViewChild('pageBottom', { read: ElementRef }) pageBottom: ElementRef;
  @ViewChild('filterTableInputField') searchInputField: ElementRef;
  @ViewChild('failureMessagesDiv') failureMessagesDiv: ElementRef;

  // Table's data
  dataSource: MatTableDataSource<UseCaseDataModel>;
  dataSourceFailureMessages: MatTableDataSource<FailureMessageVisualizationModel>;

  authorityShortName: string;
  authorityInfo: AuthorityInfoModel;
  selectedUseCase: string;
  private filterSituationRecordId: string;

  authorityPermissions: PermissionResponseModel;
  statisticsModel: StatisticsModel;

  lastDate = '2004-01-01T00:00:00Z';

  showScrollUpAndDownButton = false;
  reasonsOfFailureDescription: FailureMessageVisualizationModel[];
  reasonsOfFailureDescriptionBeforeFilter: FailureMessageVisualizationModel[];

  refreshPageTimer: Subscription;

  // Only used during page initialization
  timeFilterSelected: TimeFilter;
  timeFilterIndex: number;
  pageTitle = 'Authority Use-cases';

  hiddenAlertDanger = true;
  hiddenAlertTableContentIsLoading = false;
  failureMessagesIsLoading = false;
  downloadingFailureMessagesIsLoading = false;
  buttonDownloadFailureMessagesDisabled = false;
  buttonRefreshFailuresDisabled = false;

  interval: Subscription;

  // Failure Messages: number of messages gather from the server
  lastNMessages = 200;


  constructor(private router: Router, private activeRoute: ActivatedRoute,
              private titleService: Title,
              private appComponent: AppComponent,
              private authoritiesService: AuthoritiesService,
              private failureMessagesService: FailureMessagesService,
              private statisticsService: StatisticsService,
              private sidebarItemComponent: SidebarItemComponent,
              private snackBar: MatSnackBar)
  {
    super();
    this.titleService.setTitle(this.pageTitle);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {

    this.filterSituationRecordId = '';
    this.reasonsOfFailureDescription = [];
    this.reasonsOfFailureDescriptionBeforeFilter = [];

    this.authorityInfo = new AuthorityInfoModel();
    this.authorityShortName = this.activeRoute.snapshot.params.authorityName;

    this.timeFilterSelected = TimeFilter.LAST_WEEK;
    this.getAppComponentDateFilterParam();

    this.lastDate = this.computeUTCDate(this.timeFilterSelected);
    this.timeFilterIndex = this.getTimeFilterIndex();

    // Get the list of useCases available for the Authority
    this.getAuthorityUseCases();

    // Update data every 1 minute
    this.refreshPageTimer = interval(60000).subscribe(val => {
      this.getAuthorityUseCases();
    });

  }

  ngOnDestroy(): void {
    this.refreshPageTimer.unsubscribe();
  }

  computeUTCDate(date): string {
    // Note: today's date is computed as "yyyy-mm-YESTERDAY T 23:00:00 Z"
    // Note: it also generates milliseconds (e.g., 2015-12-02T21:45:22.279Z)
    const dateUTC = new Date(date).toISOString();
    const tmpDate = dateUTC.split('.');

    return tmpDate[0] + 'Z';
  }

  filterChanged($event: TimeFilter): void{
    this.timeFilterSelected = $event;
    this.lastDate = this.computeUTCDate($event);
    this.selectedUseCase = undefined;
    this.getAuthorityUseCases();
    this.timeFilterIndex = this.getTimeFilterIndex();

    // used to propagate information related to selected timeFilter
    this.sidebarItemComponent.updateQueryParams(this.timeFilterIndex);
  }

  private getAppComponentDateFilterParam(): void {
    // Check if the AppComponent contains a valid 'dateFilter'
    const dateFilter = this.appComponent.getDateFilterParam();
    if (dateFilter !== null) {
      if (!isNaN(dateFilter)) {
        const dateIndex = dateFilter;
        if (dateIndex >= 0 && dateIndex < this.getTimeFilterValues().length) {
          this.timeFilterSelected = this.getTimeFilterValues()[dateIndex];
          this.timeFilterIndex = dateFilter;
        }
      }
    }
  }

  getAuthorityUseCases(): void {

    this.authoritiesService.getSingleAuthorityPermissions(this.authorityShortName).pipe(
      map( respModel => {
        return respModel;
      })
    ).subscribe(respModel => {

        if (respModel !== null) {
          this.authorityPermissions = respModel;
          this.getUseCasesStatistics();
        }
        else {
          this.hiddenAlertTableContentIsLoading = true; // loading message
          this.hiddenAlertDanger = false; // error message
        }

      },
      error => {
        this.hiddenAlertTableContentIsLoading = true; // hide loading message
        this.hiddenAlertDanger = false; // show error message
      });
  }

  getTimeFilterValues(): TimeFilter[]{

    return [ TimeFilter.TODAY, TimeFilter.LAST_WEEK, TimeFilter.LAST_MONTH, TimeFilter.LAST_YEAR, TimeFilter.ALL];

  }

  getTimeFilterIndex(): number {
    const filters = this.getTimeFilterValues();

    for (let i = 0; i < filters.length; i++) {
      if (filters[i] === this.timeFilterSelected) {
        return i;
      }
    }

    return 1; // return default value
  }

  getReasonsOfFailure(): void {

    this.reasonsOfFailureDescription = [];
    this.reasonsOfFailureDescriptionBeforeFilter = [];
    this.showScrollUpAndDownButton = true;
    this.failureMessagesIsLoading = true;
    this.buttonDownloadFailureMessagesDisabled = true;

    this.failureMessagesService.getFailureMessages(this.authorityShortName, this.selectedUseCase,
      this.lastDate, this.lastNMessages).subscribe(respModel => {

      const groupedMessages: FailureMessageModel[][] = respModel.failureMessages;
      let failureMessages: FailureMessageModel[] = [];

      if ( groupedMessages.length === 0  ) {
        failureMessages.push(new FailureMessageModel(null, 'No data available', 'not set'));
        this.reasonsOfFailureDescription.push(new FailureMessageVisualizationModel(failureMessages, 'not set', 0));
      }
      else {
        groupedMessages.forEach( group => {

          failureMessages = [];
          let situationRecordId = 'not set';

          if ( group.length > 0 ) {
            situationRecordId = group[0].situationRecordID;

            group.forEach( failure => {

              failureMessages.push(new FailureMessageModel(
                failure.situationRecordID,
                failure.reasonOfFailure,
                failure.date)
              );

            });
          }

          const failureMessageGroup = new FailureMessageVisualizationModel(failureMessages, situationRecordId, group.length);
          this.reasonsOfFailureDescription.push( failureMessageGroup );

        });

        this.buttonDownloadFailureMessagesDisabled = false;
      }

      this.reasonsOfFailureDescriptionBeforeFilter = this.reasonsOfFailureDescription;
      this.failureMessagesIsLoading = false;

      this.scrollFailureMessagesIntoView();
      this.refreshTableContent();

    });
  }

  getUseCasesStatistics(): void {
    console.log('Updating use case statistics ( ' + this.authorityShortName + ' ): ' + new Date().toLocaleString());

    if (this.authorityPermissions.permissions !== null) {
      if (this.authorityPermissions.permissions.length > 0) {

        const authorityUseCases: UseCaseDataModel[] = [];

        // TODO: testing auto-scroll --------------
        // if (this.dataSource === undefined) {
        //   authorityUseCases = [];
        // }
        // else {
        //   authorityUseCases = this.dataSource.data;
        // }
        // ---------------------------------------


        const authorityFullName = this.authorityPermissions.permissions[0].name + ' from date: "' + getFormattedDate( this.lastDate ) + '"';

        // Show Toast message whenever data are refreshed. Hide the Toast after 3 seconds
        this.openSnackBar();

        this.hiddenAlertTableContentIsLoading = false; // Show loading message
        this.hiddenAlertDanger = true; // hide error message

        const authorityPermissionsLength = this.authorityPermissions.permissions[0].useCases.length;

        this.authorityPermissions.permissions[0].useCases.forEach(usecase => {

            this.statisticsService.getAuthorityStatisticsMessages(this.authorityShortName,
              usecase.name, this.lastDate).subscribe(respModel => {

                if (respModel !== null) {

                  if (respModel.statistics.length > 0) {
                    this.statisticsModel = respModel.statistics[0];
                    authorityUseCases.push({
                      useCase: usecase.name,
                      messageType: this.statisticsModel.messageType,
                      totalMessages: this.statisticsModel.totalMessages,
                      success: this.statisticsModel.numberOfSuccessful,
                      failures: this.statisticsModel.numberOfFailed,
                      inputFormat: this.statisticsModel.testInformation.format
                    });
                  } else {
                    authorityUseCases.push({
                      useCase: usecase.name,
                      messageType: 'undefined',
                      totalMessages: -1,
                      success: -1,
                      failures: -1,
                      inputFormat: 'undefined'
                    });
                  }

                  // Update use-case table only when the last use-case has been managed
                  if (authorityUseCases.length === authorityPermissionsLength) {

                    authorityUseCases.sort((a, b) => {
                      return a.useCase.localeCompare(b.useCase);
                    });

                    // Used to set the page title
                    this.authorityInfo.authorityFullName = authorityFullName;
                    this.authorityInfo.useCases = authorityUseCases;

                    // update the table content and refresh it
                    this.dataSource = new MatTableDataSource<UseCaseDataModel>(authorityUseCases);

                    this.hiddenAlertTableContentIsLoading = true;
                  }
                }

              },
              error => {
                this.hiddenAlertTableContentIsLoading = true;
                this.hiddenAlertDanger = false;
              });

          },
          error => {
            this.hiddenAlertTableContentIsLoading = true;
            this.hiddenAlertDanger = false;
          });

      }
    }
  }

  setSelectedUseCase(useCase: string): void {
    this.selectedUseCase = useCase;
    if (this.searchInputField !== undefined) {
      this.searchInputField.nativeElement.value = '';
    }
    // Get list of failures from the server
    this.getReasonsOfFailure();
  }

  navigateToUseCase(element: UseCaseDataModel): void{
    this.router.navigate([ 'authority', this.authorityShortName, element.useCase  ]);
  }

  navigateToTestingPage(element: UseCaseDataModel): void {
    this.navigatePage(this.router, this.authorityShortName, element.inputFormat, element.useCase);
  }

  private refreshTableContent(): void {
    const source = new MatTableDataSource<FailureMessageVisualizationModel>( this.reasonsOfFailureDescription );
    this.dataSourceFailureMessages = source;

    /* Note: if mat-paginator is in a hidden container, it does not get initialized when the page loads.
     Use setTimeout to properly load it before using it */
    setTimeout(() => {
      source.paginator = this.paginator;
      this.dataSourceFailureMessages = source;
    });
  }

  resetTableFilter(): void {
    this.searchInputField.nativeElement.value = '';
    this.filterSituationRecordId = '';

    this.reasonsOfFailureDescription = this.reasonsOfFailureDescriptionBeforeFilter;
    this.failureMessagesIsLoading = false;
    this.refreshTableContent();
  }

  openSnackBar(): void {
    this.snackBar.open('Data has been updated', 'Close', {
      duration: 3000
    });
  }

  scrollFailureMessagesIntoView(): void {
    this.failureMessagesDiv.nativeElement.scrollIntoView({
      behavior: 'smooth'
    });
  }

  scrollToTop(): void {
    try {
      this.pageTop.nativeElement.scrollIntoView(true);
    }
    catch (err) { }
  }

  scrollToBottom(): void {
    try {
      this.pageBottom.nativeElement.scrollIntoView(true);
    }
    catch (err) { }
  }

  toggleElement(situationRecordIdFailures: FailureMessageVisualizationModel): void {
    situationRecordIdFailures.visible = !situationRecordIdFailures.visible;
  }

  closeFailureMessages(): void {
    this.selectedUseCase = null;
    this.reasonsOfFailureDescription = [];
    this.showScrollUpAndDownButton = false;
  }

  filterTableChanged(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceFailureMessages.filter = filterValue;
  }

  /** Get the list of all failure message from the server, create an excel file and download it */
  downloadFailureMessages(useCase: string): void {
    this.hiddenAlertDanger = true;
    this.buttonDownloadFailureMessagesDisabled = true;
    this.downloadingFailureMessagesIsLoading = true;
    console.log(this.buttonDownloadFailureMessagesDisabled);

    this.failureMessagesService.downloadFailureMessages( this.authorityShortName, useCase, this.lastDate )
    .subscribe(v => {
      this.buttonDownloadFailureMessagesDisabled = false;
      this.downloadingFailureMessagesIsLoading = false;
      console.log(this.buttonDownloadFailureMessagesDisabled);
    });
  }

  /* Allow the user to manually reload failures from the server */
  refreshFailureMessages(): void {

    if ( !this.buttonRefreshFailuresDisabled ) {
      this.buttonRefreshFailuresDisabled = true;

      this.hiddenAlertDanger = true;
      this.getReasonsOfFailure();

      // enable btn click after 20 secs
      this.interval = interval(20000).subscribe(val => {
        this.buttonRefreshFailuresDisabled = false;
        this.interval.unsubscribe();
      });
    }

  }

}
