import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {Routes, RouterModule} from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {SidebarModule} from 'ng-sidebar';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { TimeFilterComponent } from './time-filter/time-filter.component';
import {MatButtonModule} from '@angular/material/button';
import { DashboardChartComponent } from './dashboard-chart/dashboard-chart.component';
import {PieChartModule} from '@swimlane/ngx-charts';
import { AuthorityMainComponent } from './authority-main/authority-main.component';
import {MatTableModule} from '@angular/material/table';
import { AuthorityUseCaseComponent } from './authority-use-case/authority-use-case.component';
import {MatChipsModule} from '@angular/material/chips';
import { EventMapComponent } from './event-map/event-map.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTabsModule} from '@angular/material/tabs';
import {Datex2ParseDataComponent} from './datex2-parse-data/datex2-parse-data.component';
import {LoginComponent} from './login/login.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AuthenticationService} from './service/authentication.service';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {HttpCustomInterceptor} from './interceptor/httpinterceptor';
import {LogoutComponent} from './logout/logout.component';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {MatDialogModule} from '@angular/material/dialog';
import {DialogComponent} from './dialog/dialog.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [ AuthenticationService ]
  },
  {
    path: 'authority/:authorityName',
    component: AuthorityMainComponent,
    canActivate: [ AuthenticationService ]
  },
  {
    path: 'authority/:authorityName/:useCase',
    component: AuthorityUseCaseComponent,
    canActivate: [ AuthenticationService ],
  },
  {
    path: 'authority/:authorityShortName/:useCase/test/:inputFormat/:dataType',
    component: Datex2ParseDataComponent,
    canActivate: [ AuthenticationService ]
  },

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {   // Manage invalid URLs
    path: '**', component: LoginComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    AuthorityMainComponent,
    AuthorityUseCaseComponent,
    DashboardComponent,
    DashboardChartComponent,
    Datex2ParseDataComponent,
    DialogComponent,
    EventMapComponent,
    LoginComponent,
    LogoutComponent,
    SidebarComponent,
    SidebarItemComponent,
    TimeFilterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {useHash: true}),
    MatSidenavModule,
    SidebarModule,
    MatButtonModule,
    PieChartModule,
    MatTableModule,
    MatChipsModule,
    MatPaginatorModule,
    MatIconModule,
    HttpClientModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatTabsModule,
    MatTooltipModule,
    MatInputModule,
    MatProgressSpinnerModule,
    NgScrollbarModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpCustomInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
