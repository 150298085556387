import {StatusOfDeliveryModel} from './status-of-delivery.model';
import {MapData} from './map.data.model';

export class MessageModel {

  readonly id: string;
  readonly messageIdentifier: string;
  readonly messageContent: string[];
  readonly sourceAuthority: string;
  readonly generationTime: string;
  readonly optionalParams: Map<string, string>;
  statusOfDelivery: StatusOfDeliveryModel;
  readonly display: MapData;

  constructor(id: string, messageIdentifier: string, messageContent: string[], sourceAuthority: string,
              generationTime: string, statusOfDelivery: StatusOfDeliveryModel, display: MapData, optionalParams: Map<string, string>)
  {
    this.id = id;
    this.messageIdentifier = messageIdentifier;
    this.messageContent = messageContent;
    this.sourceAuthority = sourceAuthority;
    this.generationTime = generationTime;
    this.statusOfDelivery = statusOfDelivery;
    this.display = display;
    this.optionalParams = optionalParams;
  }

}

