export class DashboardCityData {

  readonly authorityName: string;
  numberOfSuccessful: number;
  numberOfFailed: number;
  private totalNumber: number;
  readonly compactName: string;

  constructor(authorityName: string, numberOfSuccessful: number, numberOfFailed: number, compactName: string) {
    this.authorityName = authorityName;
    this.numberOfSuccessful = numberOfSuccessful;
    this.numberOfFailed = numberOfFailed;
    this.totalNumber = numberOfSuccessful + numberOfFailed;
    this.compactName = compactName;
  }

  public setNumberOfFailed(val: number): void {
    this.numberOfFailed = val;
    this.totalNumber = this.numberOfSuccessful + this.numberOfFailed;
  }


  public setNumberOfSuccessful(val: number): void {
    this.numberOfSuccessful = val;
    this.totalNumber = this.numberOfSuccessful + this.numberOfFailed;
  }
}
