import {Router} from '@angular/router';
import {PolylineTypeLegend} from '../model/polyline-type-legent.model';

export class UtilityFunctions {

  public HttpRequestHeaders(idToken: string): any {
    return {
      authorization: 'Bearer ' + idToken
    };
  }

  /** IMPORTANT: some useCases use different "names" in the FRONTEND. Use this function to MAP FE names with BACKEND */
  public navigatePage(router: Router, authorityShortName: string, inputFormat: string, useCase: string): void {

    switch (useCase.toUpperCase()) {
      case 'IVI-DTT': case 'IVS-EVFT': case 'IVS-DSLI': case 'IVS-OSI':
      case 'PI-OFFSTREET': case 'PI-ONSTREET':
        router.navigate([ 'authority', authorityShortName, useCase, 'test', inputFormat, 'dynamic' ]);
        break;
      default:
        router.navigate([ 'authority', authorityShortName, useCase, 'test', inputFormat, 'null' ]);
        break;
    }
  }

  public manageMapLegend(useCase: string): [string, PolylineTypeLegend[]] {

    const polylineTypeLegends: PolylineTypeLegend[] = [];

    switch (useCase.toUpperCase()) {
      case 'HLN-APR': case 'HLN-AZ': case 'HLN-EPVA': case 'HLN-VRU': case 'HLN-OR':
      case 'PVD-EDC': case 'RWW': case 'HLN-SV': case 'HLN-TJA': case 'HLN-WCW':
        polylineTypeLegends.push( new PolylineTypeLegend( 'eventhistory', 'Event History', 'EventHistory', true ));
        polylineTypeLegends.push( new PolylineTypeLegend( 'traces', 'Traces', 'Traces', true ));
        return ['DENM', polylineTypeLegends];

      case 'IVS-DSLI': case 'IVS-EVFT': case 'IVS-OSI':
      case 'IVI-DTT': case 'SMART-ROUTING':
        polylineTypeLegends.push( new PolylineTypeLegend( 'detectionarea', 'Detection Area', 'DetectionArea', true ));
        polylineTypeLegends.push( new PolylineTypeLegend( 'relevancearea', 'Relevance Area', 'RelevanceArea', true ));
        return ['IVIM', polylineTypeLegends];

      case 'SPATEM': case 'MAPEM':
        polylineTypeLegends.push( new PolylineTypeLegend( 'spatemmapem', 'SPATEM MAPEM', 'SpatemMapem', false ));
        polylineTypeLegends.push( new PolylineTypeLegend( 'ingresslane', 'INGRESS LANE', 'SpatemMapemIngress', false ));
        polylineTypeLegends.push( new PolylineTypeLegend( 'egresslane', 'EGRESS LANE', 'SpatemMapemEgress', false ));
        return [useCase.toUpperCase(), polylineTypeLegends];

      case 'POI': // Parking status use-case
      case 'POI-DYNAMIC': case 'PI-DYNAMIC': // Parking status use-case
      case 'POI-STATIC': case 'PI-STATIC': // Parking status use-case
      case 'POI-OFFSTREET': case 'PI-OFFSTREET':
      case 'PVD-VDC': // CAM
        return ['undefined', polylineTypeLegends];

      case 'PI-ONSTREET': // Parking status use-case
        // If you want to use, also go in "event-map.component.ts", function "createPOILocations" and use the if statement ['status']
        polylineTypeLegends.push( new PolylineTypeLegend( 'AVAILABLE', 'AVAILABLE', 'POIAvailable', false ));
        polylineTypeLegends.push( new PolylineTypeLegend( 'OCCUPIED', 'OCCUPIED', 'POIOccupied', false ));
        // polylineTypeLegends.push( new PolylineTypeLegend( 'RESERVED', 'RESERVED', 'POIReserved', false ));
        polylineTypeLegends.push( new PolylineTypeLegend( 'UNKNOWN', 'UNKNOWN', 'POIUnknown', false ));

        polylineTypeLegends.push( new PolylineTypeLegend( 'DISABLED_PARKING_SPACE', 'DISABLED PEOPLE', 'POIDisabledPeople', false ));
        polylineTypeLegends.push( new PolylineTypeLegend( 'LOAD_AND_OFFLOAD_GOODS', 'LOAD / OFFLOAD', 'POILoadOffload', false ));
        polylineTypeLegends.push( new PolylineTypeLegend( 'OTHER', 'OTHERS', 'POIOther', false ));
        return [useCase.toUpperCase(), polylineTypeLegends];

      case 'SSEM': case 'SI-TLP': // Traffic light priority
        polylineTypeLegends.push( new PolylineTypeLegend( 'prioritizedlane', 'PRIORITIZED LANE',
          'SsemPriorityLane', true ));  // TODO: filtro per Priority da implementare
        polylineTypeLegends.push( new PolylineTypeLegend( 'spatemmapem', 'SPATEM MAPEM', 'SpatemMapem', false ));
        polylineTypeLegends.push( new PolylineTypeLegend( 'ingresslane', 'INGRESS LANE', 'SpatemMapemIngress', false ));
        polylineTypeLegends.push( new PolylineTypeLegend( 'egresslane', 'EGRESS LANE', 'SpatemMapemEgress', false ));
        return [useCase.toUpperCase(), polylineTypeLegends];

      default: return ['undefined', polylineTypeLegends];
    }
  }

}
